// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-color {
  min-height: 25px;
  flex-flow: column wrap;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 1px 2px rgb(0, 0, 0);
  margin: 0 0 4px;
  min-width: 25px;
  height: 25px;
}

.btn-borg {
  background: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  padding: 0;
  min-width: 12px;
  --width: 1vh;
  --height: 55px;
  height: 55px;
}

.selected {
  border-radius: 5px;
  border: 1px solid var(--ion-color-primary);
}

.unselected {
  border-radius: 5px;
  border: 1px solid var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/feedback/scale-number/scale-number.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,0CAAA;AACJ;;AAEA;EACI,kBAAA;EACA,0CAAA;AACJ","sourcesContent":[".btn-color {\n    min-height: 25px;\n    flex-flow: column wrap;\n    display: block;\n    border-radius: 3px;\n    padding: 5px;\n    box-shadow: 1px 2px rgb(0, 0, 0);\n    margin: 0 0 4px;\n    min-width: 25px;\n    height: 25px;\n}\n\n.btn-borg {\n    background: transparent;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: center;\n    align-items: center;\n    margin-left: 0;\n    padding: 0;\n    min-width: 12px;\n    --width: 1vh;\n    --height: 55px;\n    height: 55px;\n}\n\n.selected {\n    border-radius: 5px;\n    border: 1px solid var(--ion-color-primary);\n}\n\n.unselected {\n    border-radius: 5px;\n    border: 1px solid var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
