import { PaginatedResponse, PaginationParameter } from './entities/paginated-response';

export class LazyLoadedList<T> {
    readonly list = new PaginatedResponse<T[]>();

    constructor(readonly numberOfItemsPerStep: number) {
        this.list.items = [];
    }

    initialParameter(): PaginationParameter {
        return {
            offset: 0,
            limit: this.numberOfItemsPerStep,
        };
    }

    refreshParameter(): PaginationParameter {
        return {
            offset: 0,
            limit: this.list.count >= this.numberOfItemsPerStep ? this.list.count : this.numberOfItemsPerStep,
        };
    }

    hasMoreItems(): boolean {
        return this.list.total === 0 || Number(this.list.offset) + this.numberOfItemsPerStep < this.list.total;
    }

    hasItems(): boolean {
        return this.list.count > 0;
    }

    nextParameter(): PaginationParameter {
        if (!this.hasMoreItems()) {
            throw new Error('End of list reached! Check for list end with hasMoreItems()');
        }
        return {
            offset: this.list.total === 0 ? 0 : Number(this.list.offset) + this.numberOfItemsPerStep,
            limit: this.numberOfItemsPerStep,
        };
    }

    init(paginatedResponse: PaginatedResponse<T[]>) {
        this.list.items = paginatedResponse.items;
        this.list.offset = Math.max(paginatedResponse.offset, paginatedResponse.count - this.numberOfItemsPerStep);
        this.list.limit = paginatedResponse.limit;
        this.list.count = paginatedResponse.count;
        this.list.total = paginatedResponse.total;
        this.list.orderBy = paginatedResponse.orderBy;
        this.list.filter = paginatedResponse.filter;
        this.list.role = paginatedResponse.role;
        this.list.filterByCols = paginatedResponse.filterByCols;
        this.list.tags = paginatedResponse.tags;
    }

    /**
     * Insert items at the start of the list.
     * @param paginatedResponse
     */
    unshift(paginatedResponse: PaginatedResponse<T[]>): void {
        this.list.items = [...paginatedResponse.items, ...this.list.items];
        this.updatePaginationState(paginatedResponse);
    }

    /**
     * Append items at the end of the list.
     * @param paginatedResponse
     */
    append(paginatedResponse: PaginatedResponse<T[]>): void {
        this.list.items = [...this.list.items, ...paginatedResponse.items];
        this.updatePaginationState(paginatedResponse);
    }

    private updatePaginationState(paginatedResponse: PaginatedResponse<T[]>) {
        this.list.count += paginatedResponse.count;
        this.list.offset = paginatedResponse.offset;
        this.list.limit = paginatedResponse.limit;
        this.list.total = paginatedResponse.total;
    }
}
