import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimelineItemTyp } from '../../common/components/curafida-timeline/timeline.entity';
import { PlanerItemResource } from '../entities/planer-item-resource';
import { ExerciseSessionState, ExerciseSessionUserState } from '../../therapy/entities/exerciseSession';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

@Pipe({
    standalone: true,
    name: 'patientJournalItemDescription',
    pure: true,
})
export class PatientJournalItemDescriptionPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(item: PlanerItemResource): string {
        if (
            item.type === TimelineItemTyp.LEARNING ||
            item.type === TimelineItemTyp.LEARNING_LEGACY ||
            item.type === TimelineItemTyp.TASK
        ) {
            return this.learningOrTaskDescription(item);
        }
        if (item.timelineDate && item.type === TimelineItemTyp.COURSE) {
            return this.courseDescription(item);
        }
        if (item.type === TimelineItemTyp.EXAMINATION) {
            return this.translateService.instant('STATE_DETAILS.CREATED_AT', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        if (item.type === TimelineItemTyp.TRAINING) {
            return this.trainingDescription(item);
        }
        if (item.type === TimelineItemTyp.USER_CHANGED_EVENT) {
            return `Geändert am ${format(new Date(item.timelineDate), 'dd.MM.yyyy HH:mm')} Uhr`;
        }
        if (item.type === TimelineItemTyp.PATIENT_REPORT_CREATED) {
            return `Erstellt am ${format(new Date(item.timelineDate), 'dd.MM.yyyy HH:mm')} Uhr`;
        }
        return '';
    }

    private learningOrTaskDescription(item: PlanerItemResource) {
        if (item.exerciseSessionUserState === ExerciseSessionUserState.FINISHED) {
            return this.translateService.instant('STATE_DETAILS.COMPLETED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        if (
            [ExerciseSessionState.PATIENT_CANCELLED.toString(), ExerciseSessionState.CANCELLED.toString()].includes(
                item.exerciseSessionUserState,
            )
        ) {
            return this.translateService.instant('STATE_DETAILS.CANCELLED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        if (item.hasPreconditions && !item.arePreconditionsCompleted) {
            return this.translateService.instant('STATE_DETAILS.INACTIVE_CAUSE_OF_PRECONDITION');
        }
        if (
            [
                ExerciseSessionState.PLANNED.toString(),
                ExerciseSessionState.NOT_PLANNED.toString(),
                ExerciseSessionState.ACTIVE.toString(),
            ].includes(item.exerciseSessionUserState)
        ) {
            if (item.delayedTime && !item.calendarEventStartDate) {
                return this.translateService.instant('STATE_DETAILS.DUE_AT', {
                    date: format(new Date(item.delayedTime), 'dd.MM.yyyy, HH:mm', { locale: de }),
                });
            }
            if (!item.delayedTime && item.calendarEventStartDate) {
                return this.translateService.instant('STATE_DETAILS.APPOINTMENT_AT', {
                    date: format(new Date(item.calendarEventStartDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
                });
            }
            if (item.delayedTime && item.calendarEventStartDate) {
                return (
                    this.translateService.instant('STATE_DETAILS.DUE_AT', {
                        date: format(new Date(item.delayedTime), 'dd.MM.yyyy, HH:mm', { locale: de }),
                    }) +
                    ',\n' +
                    this.translateService.instant('STATE_DETAILS.APPOINTMENT_AT', {
                        date: format(new Date(item.calendarEventStartDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
                    })
                );
            }
            return;
        }
        return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
            date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
        });
    }

    private courseDescription(item: PlanerItemResource) {
        if (
            [ExerciseSessionState.PATIENT_CANCELLED.toString(), ExerciseSessionState.CANCELLED.toString()].includes(
                item.state,
            )
        ) {
            return this.translateService.instant('STATE_DETAILS.CANCELLED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        } else if ([ExerciseSessionUserState.NO_SHOW].includes(item.exerciseSessionUserState)) {
            return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        return this.translateService.instant('STATE_DETAILS.START_AT', {
            date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
        });
    }

    private trainingDescription(item: PlanerItemResource) {
        if ([ExerciseSessionState.PLANNED.toString(), ExerciseSessionState.ACTIVE.toString()].includes(item.state)) {
            return this.translateService.instant('STATE_DETAILS.TRAINING', {
                completed: item.completedTraining,
                planned: item.plannedTraining,
            });
        } else if ([ExerciseSessionState.NO_SHOW.toString()].includes(item.exerciseSessionUserState)) {
            return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        } else {
            return this.translateService.instant('STATE_DETAILS.TRAINING', {
                completed: item.completedTraining,
                planned: item.plannedTraining,
            });
        }
    }
}
