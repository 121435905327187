export enum PageName {
    MEASUREMENT_DETAIL = 'MEASUREMENT_DETAIL',
    EXERCISE_DETAIL = 'EXERCISE_DETAIL',
    TRAINING_EXERCISE_DETAIL = 'TRAINING_EXERCISE_DETAIL',
    EXERCISE_LIST = 'EXERCISE_LIST',
    TASK_DETAIL = 'TASK_DETAIL',
    TASK_LIST = 'TASK_LIST',
    THERAPY_DETAIL = 'THERAPY_DETAIL',
    THERAPY_LIST = 'THERAPY_LIST',
    COURSE_TEMPLATE_DETAIL = 'COURSE_TEMPLATE_DETAIL',
    THERAPY_TEMPLATE_LIST = 'THERAPY_TEMPLATE_LIST',
    PROFILE = 'PROFILE',
    HOME = 'HOME',
    THERAPIST = 'THERAPIST',
    SUPERVISOR_DETAIL = 'SUPERVISOR_DETAIL',
    USER_LIST = 'USER_LIST',
    ORGANIZATION_MANAGEMENT = 'ORGANIZATION_MANAGEMENT',
    /** @deprecated **/
    PATIENT_MEASUREMENT_LIST = 'PATIENT_MEASUREMENT_LIST',
    CONTACT = 'CONTACT',
    PRIVACY = 'PRIVACY',
    HELP = 'HELP',
    MANUAL = 'MANUAL',
    USER_AGREEMENT = 'USER_AGREEMENT',
    CONFORMITY = 'CONFORMITY',
    GROUP_DETAIL = 'GROUP_DETAIL',
    ORGANIZATION_DETAIL = 'ORGANIZATION_DETAIL',
    PATIENT = 'PATIENT',
    COURSE_ADMINISTRATION = 'COURSE_ADMINISTRATION',
    ADMINISTRATION = 'ADMINISTRATION',
    TRAINING = 'TRAINING',
    TEMPLATE = 'TEMPLATE',
    LIST = 'LIST',
    DETAIL = 'DETAIL',
    EXERCISE = 'EXERCISE',
    PROGRESSION = 'PROGRESSION',
    TASK = 'TASK',
    PROGRAM = 'PROGRAM',
    MAIN = 'MAIN',
    NORM_VALUES = 'norm-values',
    MEASUREMENT_EXPERT = 'expert-mode-measurement',
    MOBILITY = 'MOBILITY',
    NOTE = 'NOTE',
    MEDICATION = 'MEDICATION',
    LEARNING = 'LEARNING',
    BASIC = 'BASIC',
    COMMON = 'COMMON',
    INFO = 'INFO',
    AUTO = 'AUTO',
    CHAT = 'CHAT',
    PATIENT_CHAT = 'PATIENT_CHAT',
    PATIENT_TASK = 'PATIENT_TASK',
    REGISTER = 'REGISTER',
    PATIENT_DETAIL = 'PATIENT_DETAIL',
    PROGRAMM_DETAIL = 'PROGRAMM_DETAIL',
    ANALYST = 'ANALYST',
    KNOWLEDGE = 'KNOWLEDGE',
}
