import { Pipe, PipeTransform } from '@angular/core';
import { TimelineItemTyp, TimelineState } from '../../curafida-timeline/timeline.entity';
import { PlanerItemResource } from '../../../../journal/entities/planer-item-resource';
import { ExerciseSessionState, ExerciseSessionUserState } from '../../../../therapy/entities/exerciseSession';

@Pipe({
    pure: true,
    name: 'planerItemState',
    standalone: true,
})
export class PlanerItemStatePipe implements PipeTransform {
    private readonly allStates = Object.values(TimelineState).map((it) => it.toString());

    transform(item: PlanerItemResource): TimelineState {
        if (this.allStates.includes(item.state)) {
            return item.state as TimelineState;
        }
        if (item.type === TimelineItemTyp.TRAINING) {
            if (
                [ExerciseSessionState.PLANNED.toString(), ExerciseSessionState.ACTIVE.toString()].includes(item.state)
            ) {
                return TimelineState.WARNING;
            } else if ([ExerciseSessionState.NO_SHOW.toString()].includes(item.state)) {
                return TimelineState.FAILURE;
            } else if ([ExerciseSessionState.FINISHED.toString()].includes(item.state)) {
                return TimelineState.SUCCESS;
            }
        }
        if (item.type === TimelineItemTyp.EXAMINATION) {
            return TimelineState.SUCCESS;
        }
        if (item.type === TimelineItemTyp.USER_CHANGED_EVENT) {
            return TimelineState.WARNING;
        }
        if (item.type === TimelineItemTyp.PATIENT_REPORT_CREATED) {
            return TimelineState.WARNING;
        }
        switch (item.state) {
            case ExerciseSessionState.FINISHED:
                if (item.exerciseSessionUserState === ExerciseSessionUserState.NO_SHOW) {
                    return TimelineState.FAILURE;
                }
                return TimelineState.SUCCESS;
            case ExerciseSessionState.CANCELLED:
            case ExerciseSessionState.PATIENT_CANCELLED:
            case ExerciseSessionState.NO_SHOW:
                return TimelineState.FAILURE;
            case ExerciseSessionState.ACTIVE:
                return TimelineState.WARNING;
        }

        return TimelineState.WAITING;
    }
}
