import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { NgModule } from '@angular/core';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { CurafidaCommonModule } from '../../common/curafida-common.module';
import { UserListModalComponent } from './user-list-modal/user-list-modal.component';
import { UserShortInfoComponent } from './user-short-info/user-short-info.component';
import { GroupListModalComponent } from './group-list-modal/group-list-modal.component';
import { GroupShortInfoComponent } from './group-short-info/group-short-info.component';
import { TableModule } from '../../table/table.module';
import { TableComponentsModule } from '../../table/components/table-components.module';
import { SupervisedPatientListComponent } from './supervised-patient-list/supervised-patient-list.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { PatientCareManagementComponent } from './user-main-info/patient-care-management/patient-care-management.component';
import { UserTagViewComponent } from './user-tag-view/user-tag-view.component';
import { ReportTypeModalComponent } from './report-type-list-modal/report-type-modal.component';
import { HateoasModule } from '../../hateoas/hateoas.module';
import { CurafidaTableComponent } from '../../table/components/curafida-table/curafida-table.component';
import { LoadingComponent } from '../../common/components/loading/loading.component';
import { UserRoleCardComponent } from './user-role-card/user-role-card.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UserTagFilterPipe } from '../pipes/user-tag-filter.pipe';
import { UserRoleFilterPipe } from '../pipes/user-role-filter.pipe';

@NgModule({
    declarations: [
        UserListModalComponent,
        GroupListModalComponent,
        UserShortInfoComponent,
        GroupShortInfoComponent,
        SupervisedPatientListComponent,
        RegisterFormComponent,
        PatientCareManagementComponent,
        UserTagViewComponent,
        ReportTypeModalComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        CommonComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        AutosizeModule,
        TableModule,
        TableComponentsModule,
        TranslateModule,
        CurafidaCommonModule,
        HateoasModule,
        CurafidaTableComponent,
        LoadingComponent,
        UserRoleCardComponent,
        FileUploadModule,
    ],
    exports: [
        UserListModalComponent,
        GroupListModalComponent,
        UserShortInfoComponent,
        GroupShortInfoComponent,
        SupervisedPatientListComponent,
        RegisterFormComponent,
        UserTagViewComponent,
        ReportTypeModalComponent,
        PatientCareManagementComponent,
    ],
    providers: [UserTagFilterPipe, UserRoleFilterPipe],
})
export class UserComponentsModule {}
