import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Therapy, TherapyState, TherapyWithTherapySessions } from '../../entities/therapy';
import { ApiService } from '../../../api';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';
import { forkJoin, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isAfter, startOfWeek } from 'date-fns';
import { TherapySession, TherapySessionState } from '../../entities/therapy-session/therapy-session';
import { flatMap } from 'rxjs/internal/operators';

export interface GetUserTherapiesArguments {
    username: string;
    therapyState?: TherapyState;
    offset?: number;
    limit?: number;
    filter?: string;
    sortOrder?: SortOrder;
    sortBy?: SortBy;
    therapyGoal?: TherapyGoal;
    exerciseType?: ExerciseType;
    includeTherapyChangeEvents?: boolean; // defaults to "false" in getUserTherapies
    includeExercises?: boolean; // defaults to "true" in getUserTherapies
    includeContents?: boolean; // defaults to "false" in getUserTherapies
    startDate?: string;
    endDate?: string;
    onlyNewestPerTherapy?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UsersTherapiesService {
    readonly userTherapiesWithTherapySessions$ = new Subject<PaginatedResponse<TherapyWithTherapySessions[]>>();
    readonly statisticsOfUserTherapiesWithTherapySessions$ = new Subject<
        PaginatedResponse<TherapyWithTherapySessions[]>
    >();

    constructor(
        protected http: HttpClient,
        private loadingService: LoadingService,
    ) {}

    async getUserTherapies(args: GetUserTherapiesArguments): Promise<PaginatedResponse<Therapy[]>> {
        if (args.includeExercises !== false) args.includeExercises = true;
        const url = new URL(`${ApiService.url}users/${args.username}/therapiesOfUser`);

        // build query param string
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.includeContents) url.searchParams.set('includeContents', args.includeContents.toString());
        if (args.therapyState) url.searchParams.set('therapyState', args.therapyState);
        if (args.includeTherapyChangeEvents) url.searchParams.set('includeTherapyChangeEvents', 'true');
        // because this currently defaults to true, only false needs to be sent explicitly
        if (!args.includeExercises) url.searchParams.set('includeExercises', args.includeExercises.toString());

        return this.http.get<PaginatedResponse<Therapy[]>>(url.toString(), ApiService.options).toPromise();
    }

    initUserTherapiesWithTherapySessions(args: GetUserTherapiesArguments, isStatistic = false): void {
        if (args.includeExercises !== false) args.includeExercises = true;
        const url = new URL(`${ApiService.url}users/${args.username}/therapiesOfUser`);

        // build query param string
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.filter) url.searchParams.set('filter', args.filter);
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args.therapyState) url.searchParams.set('therapyState', args.therapyState);
        if (args.includeTherapyChangeEvents) url.searchParams.set('includeTherapyChangeEvents', 'true');
        // because this currently defaults to true, only false needs to be sent explicitly
        if (!args.includeExercises) url.searchParams.set('includeExercises', args.includeExercises.toString());
        this.http
            .get<PaginatedResponse<Therapy[]>>(url.toString(), ApiService.options)
            .pipe(
                tap((i) => {
                    if (i.total === 0) {
                        this.userTherapiesWithTherapySessions$.next(
                            new PaginatedResponse<TherapyWithTherapySessions[]>(),
                        );
                    }
                }),
                flatMap((it) => {
                    it.items = it.items.filter((i) => {
                        return isAfter(new Date(i.endDate), startOfWeek(new Date(args.endDate), { weekStartsOn: 1 }));
                    });
                    /* If the items of the PaginatedResponse is an empty array, make an early return */
                    if (!it.items || it.items.length < 1) {
                        return of(it as PaginatedResponse<TherapyWithTherapySessions[]>);
                    }
                    return forkJoin([
                        ...it.items.map((therapy: TherapyWithTherapySessions) => {
                            const url = new URL(
                                `${ApiService.url}users/${args.username}/therapies/${therapy.id}/therapySessions`,
                            );
                            url.searchParams.set('sortOrder', SortOrder.ASC);
                            url.searchParams.set('offset', '0');
                            url.searchParams.set('limit', therapy.therapySessionsPerWeek?.toString() ?? '0');
                            url.searchParams.set('state', TherapySessionState.RUNNING);
                            if (isStatistic) {
                                url.searchParams.set('earliestStart', args.startDate);
                                url.searchParams.set('latestStart', args.endDate);
                            }
                            const runningTherapySession$ = this.http.get<PaginatedResponse<TherapySession[]>>(
                                url.toString(),
                                ApiService.options,
                            );

                            url.searchParams.set('state', TherapySessionState.COMPLETED);
                            const completedTherapySessions$ = this.http.get<PaginatedResponse<TherapySession[]>>(
                                url.toString(),
                                ApiService.options,
                            );

                            return forkJoin([runningTherapySession$, completedTherapySessions$]).pipe(
                                map((item) => {
                                    therapy.runningTherapySession = item[0];
                                    therapy.completeTherapySession = item[1];
                                    return therapy;
                                }),
                            );
                        }),
                    ]).pipe(
                        map((items) => {
                            it.items = items;
                            return it as PaginatedResponse<TherapyWithTherapySessions[]>;
                        }),
                    );
                }),
            )
            .subscribe((result: PaginatedResponse<TherapyWithTherapySessions[]>) => {
                if (isStatistic) {
                    this.statisticsOfUserTherapiesWithTherapySessions$.next(result);
                } else {
                    this.userTherapiesWithTherapySessions$.next(result);
                }
            });
    }

    async getUserTherapy(
        username: string,
        therapyId: number,
        includeTags = false,
        includeChildTherapies = false,
    ): Promise<Therapy> {
        const url = new URL(`${ApiService.url}users/${username}/therapies/${therapyId}`);
        if (includeTags) url.searchParams.set('includeTags', String(includeTags));
        if (includeChildTherapies) url.searchParams.set('includeChildTherapies', String(includeChildTherapies));

        return this.http.get<Therapy>(url.toString(), ApiService.options).toPromise();
    }

    async assignTherapyToUser(usernames: string[], therapyId: number): Promise<Therapy> {
        const patientToTherapyDTO = [];
        usernames.forEach((value) => patientToTherapyDTO.push({ username: value }));
        const url = `${ApiService.url}therapies/${therapyId}/patients`;
        return this.http.put<Therapy>(url, patientToTherapyDTO, ApiService.options).toPromise();
    }

    async removeTherapyUser(username: string, therapyId: number): Promise<any> {
        return this.http
            .delete(`${ApiService.url}users/${username}/therapies/${therapyId}`, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}
