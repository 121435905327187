// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segment-md .segment-button {
  font-size: 1.6rem;
  text-transform: none;
  color: black;
}

.segment-md .segment-button.segment-activated {
  border-color: black;
}

.explanation-sub-title {
  font-weight: bolder;
}

ion-col {
  margin: 5px;
}

.question-card .msg {
  background: var(--ion-color-primary);
  border-radius: 0 5px 5px 5px;
  box-shadow: -1px 2px 0 #c1cbcd;
  padding: 15px;
  color: var(--ion-color-primary-contrast);
}

.question-card:before {
  content: "";
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid var(--ion-color-primary);
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.feedback li {
  padding: 0.1rem;
  overflow: hidden;
  display: flex;
}

ol {
  padding: 0 !important;
}

.feedback-button {
  width: 15rem;
}

.feedback-button-label {
  margin-left: 0;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/feedback/feedback/feedback.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,oBAAA;EACA,YAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,oCAAA;EACA,4BAAA;EACA,8BAAA;EACA,aAAA;EACA,wCAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,0CAAA;EACA,8BAAA;EACA,gCAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,aAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,cAAA;EACA,2BAAA;EACA,mBAAA;AACJ","sourcesContent":[".segment-md .segment-button {\n    font-size: 1.6rem;\n    text-transform: none;\n    color: black;\n}\n\n.segment-md .segment-button.segment-activated {\n    border-color: black;\n}\n\n.explanation-sub-title {\n    font-weight: bolder;\n}\n\nion-col {\n    margin: 5px;\n}\n\n.question-card .msg {\n    background: var(--ion-color-primary);\n    border-radius: 0 5px 5px 5px;\n    box-shadow: -1px 2px 0 #c1cbcd;\n    padding: 15px;\n    color: var(--ion-color-primary-contrast);\n}\n\n.question-card:before {\n    content: '';\n    position: relative;\n    top: 0;\n    right: 0;\n    left: 0;\n    width: 0;\n    height: 0;\n    border: 5px solid var(--ion-color-primary);\n    border-left-color: transparent;\n    border-bottom-color: transparent;\n}\n\n.feedback li {\n    padding: 0.1rem;\n    overflow: hidden;\n    display: flex;\n}\n\nol {\n    padding: 0 !important;\n}\n\n.feedback-button {\n    width: 15rem;\n}\n\n.feedback-button-label {\n    margin-left: 0;\n    justify-content: flex-start;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
