import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FeedBack, FeedbackExerciseParams } from '../../../../entities/feedback';
import { ToastService } from '../../../../../common/services/toast-service/toast-service.service';
import { ExerciseSessionState } from '../../../../entities/exerciseSession';
import { EffortUnit, FeedbackCommentUnit } from '../../../../entities/exerciseGoal/exerciseGoalDto';
import { TrainingDefinition, TrainingExercise } from '../../../../entities/training/training';
import { TrainingState } from '../../../../entities/therapy';

@Component({
    selector: 'lib-feedback-free-training',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent {
    @Input()
    training: TrainingDefinition;
    @Input()
    exercise: TrainingExercise;
    @Input()
    unfinishedExercises: number;
    @Input()
    stateOfTraining: TrainingState;
    @Input()
    isExerciseLeave: boolean;

    title = 'Feedback';
    feedBack: FeedBack;
    errorMessage: string;
    finishExerciseButtonIsDisabled = true;
    isButtonDisable = false;
    TrainingState = TrainingState;

    constructor(
        public modalCtrl: ModalController,
        public params: NavParams,
        protected toastService: ToastService,
    ) {
        this.exercise = params.get('exercise');
        this.isExerciseLeave = params.get('isExerciseLeave');
        this.errorMessage = `Bitte geben Sie Ihren Borg-Wert an, oder wählen Sie Übung abgebrochen, wenn Sie die Übung nicht vollständig durchgeführt haben.`;
        this.checkIfFinishExerciseButtonIsDisabled();
    }

    async dismiss() {
        await this.modalCtrl.dismiss();
    }

    async finishExercise() {
        this.exercise.state = ExerciseSessionState.FINISHED;
        await this.modalCtrl.dismiss({
            exercise: this.exercise,
            exerciseState: ExerciseSessionState.FINISHED,
            isCanceled: false,
            feedback: this.feedBack,
        });
    }

    checkIfFinishExerciseButtonIsDisabled() {
        this.finishExerciseButtonIsDisabled = false;
    }

    setExercise(feedback: FeedBack) {
        this.feedBack = feedback;
        this.validateFinishButton();
        this.checkIfFinishExerciseButtonIsDisabled();
    }

    validateFinishButton() {
        if (this.feedBack?.exerciseParams?.length === 0) {
            delete this.feedBack.exerciseParams;
            this.isButtonDisable = this.isBorgFeedbackSetAndUnvalid();
        } else {
            if (this.exerciseParamsUnequalGoal()) {
                this.isButtonDisable = this.isBorgFeedbackSetAndUnvalid() || !this.feedBack.feedbackComment;
            } else {
                this.isButtonDisable = this.isBorgFeedbackSetAndUnvalid();
            }
        }
        if (
            !this.exerciseParamsUnequalGoal() &&
            this.exercise.exerciseGoal.feedbackCommentUnit !== FeedbackCommentUnit.FEEDBACK_COMMENT
        ) {
            delete this.feedBack.feedbackComment;
        }
    }

    exerciseParamsUnequalGoal() {
        if (this.feedBack?.exerciseParams) {
            for (const item of this.feedBack?.exerciseParams) {
                if (
                    item['intensity'] !== this.exercise.exerciseGoal.intensity ||
                    item['duration'] !== this.exercise.exerciseGoal.duration ||
                    item['pulse'] !== this.exercise.exerciseGoal.pulse
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    isBorgFeedbackSetAndUnvalid() {
        if (this.exercise.exerciseGoal.effortUnit === EffortUnit.BORG_0_TO_10) {
            if (this.feedBack?.borg === null || this.feedBack?.borg === undefined) {
                return true;
            }
        }
        return false;
    }
}
