import { Component, OnInit } from '@angular/core';
import { MyMedaxService } from '../../services/my-medax.service';
import { ModalController, NavParams } from '@ionic/angular';
import { User, UserRoles } from '../../../auth/entities/user';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { QuestionnaireTemplate } from '../../entities/questionnaire-template';
import {
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../table/entities/table';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { MyMedaxFillLink } from '../../entities/my-medax-fill-link';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { CurafidaAuthService } from '../../../auth/services';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { StyleService } from '../../../common/services/style/style.service';
import { BrowserNavigationService } from '../../../common/services/browser-navigation/browser-navigation.service';

@Component({
    selector: 'lib-my-medax-patient-form-modal',
    templateUrl: './my-medax-patient-form-modal.component.html',
    styleUrls: ['./my-medax-patient-form-modal.component.scss'],
})
export class MyMedaxPatientFormModalComponent implements OnInit {
    readonly UserRoles = UserRoles;
    protected readonly log: Logger;
    user: User;
    userRoles: UserRoles[] = [];
    questionnaireTemplateTableConfig: TableConfig<QuestionnaireTemplate[]> = new TableConfig<QuestionnaireTemplate[]>();
    searchTerm: string;
    private offset = 0;

    constructor(
        private myMedaxService: MyMedaxService,
        private modalCtrl: ModalController,
        private params: NavParams,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private loggingService: LoggingService,
        private styleService: StyleService,
        private browser: BrowserNavigationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.user = await this.params.get('user');
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];
        this.initTableConfig();
        await this.getQuestionnaireTemplates({ offset: 0 }, this.searchTerm);
    }

    async getRoles(): Promise<UserRoles[]> {
        return (await this.authService.getRoles()) as UserRoles[];
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async getQuestionnaireTemplates(value: TableUpdateValue, searchTerm: string) {
        this.offset = value.offset;
        this.searchTerm = searchTerm;
        try {
            let questionnaireTemplates: QuestionnaireTemplate[] = (
                await this.myMedaxService.getQuestionnaireTemplates()
            ).items;
            questionnaireTemplates = questionnaireTemplates.filter((qt) => !qt.isImpermanent);
            questionnaireTemplates = questionnaireTemplates.filter((qt) => {
                const matchingRoles = this.userRoles.filter((r) => qt.roleListing?.includes(r));
                return matchingRoles.length > 0;
            });
            this.questionnaireTemplateTableConfig.list.items = questionnaireTemplates;
            this.questionnaireTemplateTableConfig.list.limit = questionnaireTemplates.length;
            this.questionnaireTemplateTableConfig.list.count = questionnaireTemplates.length;
            this.questionnaireTemplateTableConfig.list.total = questionnaireTemplates.length;
            this.questionnaireTemplateTableConfig.list.offset = value.offset;
        } catch (e) {
            this.log.error('Error in getQuestionnaireTemplates', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.questionnaireTemplateTableConfig.list = new PaginatedResponse<QuestionnaireTemplate[]>();
        }
    }

    async openMyMedax(value) {
        try {
            const baseUrl = window.location.href.split('/').slice(0, 3);
            let returnUri = baseUrl.join('/');
            returnUri =
                `${returnUri}/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.DETAIL}/${this.user.username}?segment=LOG&fromMyMedax=true`;
            if (this.styleService.isMobile$) {
                returnUri = null;
            }
            const fillLink: MyMedaxFillLink = await this.myMedaxService.getFillLink(
                this.user.username,
                (value.item as QuestionnaireTemplate).id,
                returnUri,
            );
            this.browser.openTargetSelf(fillLink.url);
        } catch (e) {
            this.log.error('Error in openMyMedax', e);
            await this.toastService.showToast(
                'Beim Öffnen des Formulars ist ein Fehler aufgetreten.',
                IonicColor.danger,
            );
        }
    }

    private initTableConfig() {
        this.questionnaireTemplateTableConfig.emptyListLabel = 'Keine Formulare vorhanden';
        this.questionnaireTemplateTableConfig.isOpenDetailEnable = false;
        this.questionnaireTemplateTableConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Formular',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '90%',
                columnPosition: 0,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'open-outline',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '10%',
                columnPosition: 1,
            },
        ];
    }
}
