import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScaleItem } from '../../../../entities/scale-item';

@Component({
    selector: 'lib-scale-nummer',
    templateUrl: './scale-number.component.html',
    styleUrls: ['./scale-number.component.scss'],
})
export class ScaleNumberComponent {
    @Input()
    definition: ScaleItem[];
    @Input()
    explanation: string;

    @Input()
    label: string;
    @Input()
    value;

    @Output()
    valueChanged = new EventEmitter<number>();

    labelValue = '';

    emitChangeValue(value: number) {
        this.value = value;
        this.valueChanged.emit(value);
        this.labelValue = this.definition.find((i) => i.value === value).label;
    }
}
