import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RoutingSegment } from '../../../../../common/entities/routing-segment';
import {
    ExerciseSessionDto,
    ExerciseSessionOfUser,
    ExerciseSessionState,
    ExerciseSessionType,
    ExerciseSessionUserState,
    ExerciseType,
} from '../../../../entities/exerciseSession';
import { Router } from '@angular/router';
import { User, UserRoles } from '../../../../../auth/entities/user';
import { ActionType, ItemType } from '../../../../../table/entities/table';
import { ToastService } from '../../../../../common/services/toast-service/toast-service.service';
import { ExerciseSessionsService } from '../../../../services/exercise-sessions';
import { IonicColor } from '../../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../../services/user-exercise-sessions';
import { UsersService } from '../../../../../user/services/user';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { CurafidaAuthService } from '../../../../../auth/services';
import { ExerciseSessionUserResult } from '../../../../entities/exerciseSession/exercise-session-user-result';
import { Exercise } from '../../../../entities/exercise';
import { QuestionnaireTemplate } from '../../../../../my-medax/entities/questionnaire-template';
import { ExercisesService } from '../../../../services/exercises';
import { ModalAlertService } from '../../../../../common/services/modal';
import { ExerciseListModalComponent } from '../../../modal/exercise-list-modal/exercise-list-modal.component';
import { UserListModalComponent } from '../../../../../user/components/user-list-modal/user-list-modal.component';
import { ExerciseSessionAppointmentDto } from '../../../../entities/appointement';
import { ModalConfig } from '../../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../../common/entities/modal/modal-button';
import { MimeTypeCurafida } from '../../../../../common/entities/mime.type';
import { SegmentType } from '../../../../../common/entities/segment.type';
import { Content } from '../../../../entities/content';
import { TherapyXApiService } from '../../../../services/therapy-xapi/therapy-x-api.service';
import { IXapiCourseInfo } from '../../../../entities/xapi/x-api-course-information';
import { XapiContentMimeType } from '../../../../entities/xapi/xapi-content-mime-type.enum';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { isFuture, isPast } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { CurafidaPopoverSelectionComponent } from '../../../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { LearningActionsPipe } from '../../../../pipes/learning-actions.pipe';
import Timeout = NodeJS.Timeout;
import { ArticulateContentsService } from '../../../../services/articulate/articulate-contents.service';

@Component({
    selector: 'lib-patient-learning-detail',
    templateUrl: './patient-learning-detail.component.html',
    styleUrls: ['./patient-learning-detail.component.scss'],
})
export class PatientLearningDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    learningResource: ExerciseSessionOfUser;
    @Input()
    learningModuleInformation: IXapiCourseInfo;
    @Input()
    isMobile = false;
    @Input()
    isNew = false;
    @Input()
    patient: User;
    learningTitle: string;
    content: Content = new Content();
    Content = Content;
    userRoleOfBetreuer = UserRoles.CAREGIVER;
    taskResult: ExerciseSessionUserResult;
    patientLearningForm: FormGroup;
    responsibleSelect: { value: string; label: string }[] = [
        { value: UserRoles.PATIENT, label: `USER.${UserRoles.PATIENT.toString()}.SINGULAR` },
        { value: this.userRoleOfBetreuer, label: `USER.${this.userRoleOfBetreuer.toString()}.SINGULAR` },
        { value: UserRoles.SUPERVISOR, label: `USER.${UserRoles.SUPERVISOR.toString()}.SINGULAR` },
    ];
    isEditEnabled = false;
    title = 'LEARNING';
    subtitle = '';
    finishedTaskStates = [ExerciseSessionState.FINISHED, ExerciseSessionState.CANCELLED];
    selectedTaskTemplate: Exercise;
    selectedUser: User;
    exerciseSessionState = ExerciseSessionState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    hideActions = false;
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isCheckBoxChecked = false;
    hasTimespan = false;
    isStartTimeInFuture = false;
    isEndTimeInPast = false;
    isArticulateModule = false;
    articulateLaunchUrl: string = null;
    finishedLessons: string[] = [];
    isButtonDisable = false;
    xapiCourseInfo: IXapiCourseInfo;
    appointment: ExerciseSessionAppointmentDto;
    protected readonly log: Logger;
    protected readonly ExerciseSessionUserState = ExerciseSessionUserState;
    protected readonly ExerciseSessionState = ExerciseSessionState;
    private updateInterval: Timeout;
    private inFullScreenMode = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private exercisesService: ExercisesService,
        private exerciseSessionService: ExerciseSessionsService,
        private userService: UsersService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private modalAlertService: ModalAlertService,
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private therapyXApiService: TherapyXApiService,
        private loggingService: LoggingService,
        private so: ScreenOrientation,
        private translateService: TranslateService,
        private popoverController: PopoverController,
        readonly learningActionsPipe: LearningActionsPipe,
        private readonly articulateContentsService: ArticulateContentsService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])
    async onFullScreenChange() {
        if (this.inFullScreenMode) {
            await this.so.lock(this.so.ORIENTATIONS.PORTRAIT_PRIMARY);
        } else {
            this.so.unlock();
        }
        this.inFullScreenMode = !this.inFullScreenMode;
    }

    ngOnDestroy(): void {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession()?.user;
        await this.initPage();
    }

    ngAfterViewInit(): void {
        if (!this.isNew) {
            this.isArticulateModule = this.learningResource.exerciseSession.exercise.contents.some(
                (c) => c.mimeType === XapiContentMimeType.COURSE,
            );
            if (this.isArticulateModule) {
                this.therapyXApiService
                    .getLaunchUrl(this.patient.username, this.learningResource.exerciseSession.id)
                    .then((launchUrl) => {
                        this.articulateLaunchUrl = launchUrl.url;
                    });
            }
        }
    }

    async initPage() {
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];
        if (this.isNew && !this.userRoles.includes(UserRoles.manage_therapy)) {
            this.navCtrl.back();
        }
        if (this.isNew) {
            this.isEditEnabled = true;
            this.hasTimespan = false;
        } else {
            this.isEditEnabled = false;
            this.taskResult = this.learningResource.exerciseSessionUserResults[0];
            if (this.userRoles.includes(UserRoles.manage_therapy)) {
                this.selectedTaskTemplate = await this.exercisesService.getExerciseById(
                    Number(this.learningResource.exerciseSession.exercise_id),
                );
                if (this.learningResource.exerciseSession.responsible) {
                    this.selectedUser = await this.userService.getUser(
                        this.learningResource.exerciseSession.responsible,
                    );
                }
            } else {
                this.selectedTaskTemplate = await this.userExerciseSessionsService.getExerciseOfUserExerciseSession(
                    this.patient.username,
                    this.learningResource.exerciseSession.id,
                );
            }
            if (this.learningResource?.exerciseSession.responsibleUserRole === UserRoles.PATIENT) {
                this.selectedUser = this.patient;
            }
            await this.initForm();
            this.xapiCourseInfo = await this.therapyXApiService.getCourseInfo(
                this.patient.username,
                this.learningResource.exerciseSession.id,
            );
            this.xapiCourseInfo.lessons.sort((xl1, xl2) => {
                return xl1?.ordinal - xl2?.ordinal;
            });
            this.finishedLessons =
                (
                    await this.therapyXApiService.getFinishedLessions(
                        this.patient.username,
                        this.learningResource.exerciseSession.id,
                    )
                )?.lessonIds ?? [];
        }
        this.initLearningModuleForm();
        if (this.learningResource?.exerciseSession) {
            this.isCheckBoxChecked = this.finishedTaskStates.includes(
                this.learningResource.exerciseSession.exerciseSessionState,
            );
        }

        this.patientLearningForm.get('learningTitle').setValue(this.learningTitle);
    }

    async initForm() {
        if (!this.selectedTaskTemplate) return;
        if (!this.selectedTaskTemplate.contents) {
            this.selectedTaskTemplate.contents = await this.exercisesService.getContentsFromExercise(
                this.selectedTaskTemplate.id,
            );
        }

        this.learningTitle = this.selectedTaskTemplate.contents.find(
            (c) => c.mimeType === XapiContentMimeType.COURSE,
        )?.data;

        if (
            this.selectedTaskTemplate.contents.length > 0 &&
            this.selectedTaskTemplate.contents[0].mimeType === MimeTypeCurafida.MY_MEDAX
        ) {
            this.form = this.selectedTaskTemplate.contents[0].jsonData as QuestionnaireTemplate;
            if (this.form.isImpermanent) {
                this.hideActions = this.loggedInUser.username !== this.patient.username;
            }
        } else {
            this.form = null;
        }
    }

    cancelEdit() {
        if (this.isEditEnabled) {
            this.toggleEdit();
            this.initLearningModuleForm();
        }
    }

    toggleEdit() {
        this.isEditEnabled = !this.isEditEnabled;
        const taskTemplateTitle = this.patientLearningForm.get('taskTemplateTitle');
        const title = this.patientLearningForm.get('title');
        const description = this.patientLearningForm.get('description');
        const responsibleRole = this.patientLearningForm.get('responsibleRole');
        this.isEditEnabled ? taskTemplateTitle.enable() : taskTemplateTitle.disable();
        this.isEditEnabled ? title.enable() : title.disable();
        this.isEditEnabled ? description.enable() : description.disable();
        this.isEditEnabled ? responsibleRole.enable() : responsibleRole.disable();
    }

    async returnToList() {
        await this.modalCtrl.dismiss();
    }

    async openLearningModuleTemplateModal() {
        if (this.patientLearningForm.controls.taskTemplateTitle.disabled) return;
        const exercises = this.selectedTaskTemplate ? [this.selectedTaskTemplate] : [];
        const modal = await this.modalCtrl.create({
            component: ExerciseListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: this.translateService.instant('LEARNING.TEMPLATE.ITEM.SELECT'),
                searchString: this.translateService.instant('LEARNING.TEMPLATE.ITEM.SEARCH'),
                selectedExercises: exercises,
                isMultipleChoice: false,
                exerciseType: ExerciseType.LEARNING,
                anyItem: 'ANY_LEARNING_TEMPLATE',
                hasFilter: false,
                hasTags: false,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            this.selectedTaskTemplate = data[0];
            this.patientLearningForm.controls.taskTemplateTitle.patchValue(this.selectedTaskTemplate.title);
            this.patientLearningForm.controls.title.patchValue(this.selectedTaskTemplate.title);
            this.patientLearningForm.controls.description.patchValue(this.selectedTaskTemplate.description);
            await this.initForm();
            this.patientLearningForm.controls.learningTitle.patchValue(this.learningTitle);
            const formContent = this.selectedTaskTemplate.contents
                ? this.selectedTaskTemplate.contents.find((c) => c.mimeType === MimeTypeCurafida.MY_MEDAX)
                : null;
            if (formContent) {
                this.patientLearningForm.controls.taskType.patchValue('FORM');
                const questionnaireTemplate = formContent.jsonData as QuestionnaireTemplate;
                if (questionnaireTemplate.isImpermanent) {
                    this.patientLearningForm.controls.responsibleRole.disable();
                } else {
                    this.patientLearningForm.controls.responsibleRole.enable();
                }
            } else {
                this.patientLearningForm.controls.taskType.patchValue(ExerciseType.LEARNING);
                this.patientLearningForm.controls.responsibleRole.enable();
            }
            this.patientLearningForm.controls.responsibleRole.patchValue(this.selectedTaskTemplate.responsibleUserRole);
            if (this.form) {
                this.patientLearningForm.controls.form.patchValue(this.form.title);
            }
            this.updateResponsibleControl();
        }
    }

    onAppointmentChanged(exerciseSessionAppointment: ExerciseSessionAppointmentDto) {
        this.appointment = exerciseSessionAppointment;
        this.patientLearningForm.controls['appointment'].patchValue(this.appointment);
    }

    async openUserModal() {
        if (this.patientLearningForm.controls.responsible.disabled) return;
        const responsibleRole = this.patientLearningForm.controls.responsibleRole.value;
        if (responsibleRole === UserRoles.PATIENT) return;
        const tableItems = [
            {
                id: 'selected',
                prop: 'selected',
                name: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                sortOrderWeb: 0,
            },
            {
                id: 'lastname',
                prop: 'lastname',
                name: this.translateService.instant('LAST_NAME'),
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 1,
            },
            {
                id: 'firstname',
                prop: 'firstname',
                name: this.translateService.instant('FIRST_NAME'),
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 2,
            },
            {
                id: 'username',
                prop: 'username',
                name: this.translateService.instant('USERNAME'),
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 3,
            },
        ];
        const users = this.selectedUser ? [this.selectedUser] : [];
        let noItemsText = 'ANY_USER';
        if (responsibleRole === UserRoles.SUPERVISOR) {
            noItemsText = this.translateService.instant('USER.PATIENT.ANY_SUPERVISOR_ASSIGN');
        }
        const modal = await this.modalCtrl.create({
            component: UserListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: this.translateService.instant('USER.SELECT'),
                selectedUsers: users,
                isMultipleChoice: false,
                roles: [responsibleRole],
                tableItems,
                searchString: this.translateService.instant('USER.SEARCH'),
                anyItem: noItemsText,
                concernedUser: this.patient.username,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            const selectedUser = data[0] as User;
            this.selectedUser = selectedUser;
            this.patientLearningForm.controls.responsible.patchValue(
                `${selectedUser.firstname} ${selectedUser.lastname}`,
            );
        }
    }

    updateResponsibleControl() {
        const role = this.patientLearningForm.controls.responsibleRole.value;
        if (role === UserRoles.PATIENT) {
            this.patientLearningForm.controls.responsible.disable();
            this.patientLearningForm.controls.responsible.patchValue(
                `${this.patient.lastname}, ${this.patient.firstname}`,
            );
            this.selectedUser = this.patient;
        } else {
            this.selectedUser = null;
            this.patientLearningForm.controls.responsible.patchValue(``);
            this.patientLearningForm.controls.responsible.markAsDirty();
            this.patientLearningForm.controls.responsible.enable();
        }
    }

    async savePatientTask() {
        this.isButtonDisable = true;
        const newPatientTask = new ExerciseSessionDto();
        newPatientTask.exercise_id = this.selectedTaskTemplate.id;
        newPatientTask.exerciseSessionType = ExerciseSessionType.HOMEWORK;
        newPatientTask.title = this.patientLearningForm.controls.title.value;
        newPatientTask.description = this.patientLearningForm.controls.description.value;
        newPatientTask.responsibleUserRole = this.patientLearningForm.controls.responsibleRole.value;
        newPatientTask.responsible = this.selectedUser ? this.selectedUser.username : null;
        newPatientTask.exerciseSessionState = ExerciseSessionState.NOT_PLANNED;
        newPatientTask.exerciseType = ExerciseType.LEARNING;
        newPatientTask.needsSchedule = this.hasTimespan;
        newPatientTask.autoActivation = true;
        newPatientTask.autoNoShow = this.hasTimespan;

        try {
            const createdPatientTaskId = await this.userExerciseSessionsService.postUsersExerciseSession(
                this.patient.username,
                newPatientTask,
            );
            if (newPatientTask.needsSchedule) {
                // This method cannot be called by supervisors.
                try {
                    await this.exerciseSessionService.putAppointmentToExerciseSession(
                        createdPatientTaskId,
                        this.appointment,
                    );
                } catch (e) {
                    await this.exerciseSessionService.deleteExerciseSession(createdPatientTaskId);
                    await this.toastService.showToast(
                        this.translateService.instant('APPOINTMENT_CREATED_FAILURE_MESSAGE'),
                        IonicColor.danger,
                    );
                    await this.returnToList();
                    return;
                }
            }
            try {
                const userExerciseSession = await this.userExerciseSessionsService.getExerciseSessionOfUser(
                    this.patient.username,
                    createdPatientTaskId,
                );
                // @deprecated in newer backend versions (still needed in API v7.36.1, see Ticket #2571)
                if (
                    userExerciseSession.exerciseSession.exerciseSessionState === ExerciseSessionState.SCHEDULED ||
                    userExerciseSession.exerciseSession.exerciseSessionState === ExerciseSessionState.NOT_PLANNED
                ) {
                    await this.exerciseSessionService.postExerciseSessionConfirmPlanning(createdPatientTaskId);
                    if (!newPatientTask.needsSchedule && newPatientTask.autoActivation) {
                        await this.exerciseSessionService.postExerciseSessionActivate(createdPatientTaskId);
                    }
                }
            } catch (e) {
                await this.exerciseSessionService.deleteExerciseSession(createdPatientTaskId);
                await this.toastService.showToast(
                    this.translateService.instant('APPOINTMENT_CREATED_FAILURE_MESSAGE'),
                    IonicColor.danger,
                );
                await this.returnToList();
                return;
            }
            // This method cannot be called by supervisors.
            await this.router.navigate(
                [
                    RoutingSegment.MEMBER,
                    RoutingSegment.PATIENT_MANAGEMENT,
                    RoutingSegment.DETAIL,
                    this.patient.username,
                ],
                { replaceUrl: true, queryParams: { segment: SegmentType.LEARNING } },
            );
            await this.toastService.showToast(
                this.translateService.instant('LEARNING_SAVE_SUCCESS_MESSAGE'),
                IonicColor.success,
            );
        } catch (e) {
            this.log.error('Error in savePatientTask', e);
            this.isButtonDisable = false;
            await this.toastService.showToast(
                this.translateService.instant('LEARNING_SAVE_FAILURE_MESSAGE'),
                IonicColor.danger,
            );
        } finally {
            await this.modalCtrl.dismiss();
        }
    }

    async showDeletePrompt() {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${this.learningResource.exerciseSession.title} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = this.translateService.instant('TAG_LEARNING_IS_DELETE', {
            label: this.learningResource.exerciseSession.title,
        });

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            await this.deletePatientTask();
        }
    }

    async deletePatientTask() {
        try {
            await this.userExerciseSessionsService.postExerciseSessionUserPatientCancel(
                this.patient.username,
                this.learningResource.exerciseSession.id,
            );
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.PATIENT_MANAGEMENT,
                RoutingSegment.DETAIL,
                this.patient.username,
            ]);
            await this.toastService.showToast(
                this.translateService.instant('DELETE_TASK_SUCCESS_MESSAGE'),
                IonicColor.success,
            );
        } catch (e) {
            this.log.error('Error in deletePatientTask', e);
            await this.toastService.showToast(
                this.translateService.instant('DELETE_TASK_FAILURE_MESSAGE'),
                IonicColor.danger,
            );
        }
    }

    openArticulateCourse() {
        this.updateInterval = setInterval(async () => {
            this.xapiCourseInfo = await this.therapyXApiService.getCourseInfo(
                this.patient.username,
                this.learningResource.exerciseSession.id,
            );
            this.xapiCourseInfo.lessons.sort((xl1, xl2) => {
                return xl1?.ordinal - xl2?.ordinal;
            });
            this.finishedLessons =
                (
                    await this.therapyXApiService.getFinishedLessions(
                        this.patient.username,
                        this.learningResource.exerciseSession.id,
                    )
                )?.lessonIds ?? [];
            this.initLearningModuleForm();
            if (this.learningResource?.exerciseSession) {
                this.isCheckBoxChecked = this.finishedTaskStates.includes(
                    this.learningResource.exerciseSession.exerciseSessionState,
                );
            }
        }, 10000);

        this.articulateContentsService.openArticulateCourseWithIab(this.articulateLaunchUrl);
    }

    async cancelLearning() {
        if (
            !(
                this.learningResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED ||
                this.learningResource.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE
            )
        ) {
            await this.toastService.showToast('CANCEL_LEARNING_FAILURE', IonicColor.danger);
        }
        try {
            if (this.loggedInUser.roles.includes(UserRoles.PATIENT)) {
                await this.userExerciseSessionsService.postExerciseSessionUserCancel(
                    this.patient.username,
                    this.learningResource.exerciseSession.id,
                );
            } else {
                await this.exerciseSessionService.postExerciseSessionCancel(this.learningResource.exerciseSession.id);
            }
            this.learningResource.exerciseSessionUserState = ExerciseSessionUserState.PATIENT_CANCELLED;
            await this.toastService.showToast('CANCEL_LEARNING_MESSAGE', IonicColor.success);
        } catch (e) {
            await this.toastService.showToast('CANCEL_LEARNING_FAILURE', IonicColor.danger);
        }
    }

    onHasTimeSpanClicked(value) {
        this.hasTimespan = value.detail.checked;
        if (this.hasTimespan) {
            this.patientLearningForm.controls['appointment'].setValidators(Validators.required);
            return;
        }
        this.patientLearningForm.controls['appointment'].removeValidators(Validators.required);
    }

    async dismissModal() {
        await this.modalCtrl.dismiss('true');
    }

    async presentActionButtons(event: MouseEvent) {
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            translucent: true,
            event: event,
            componentProps: {
                actionItems: this.learningActionsPipe.transform(this.learningResource),
            },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data === ActionType.CANCELED) await this.cancelLearning();
        if (data === ActionType.UPDATE) await this.enableControllerToUpdate();
    }

    async updateLearning() {
        this.isButtonDisable = true;

        if (this.hasTimespan) {
            // This method cannot be called by supervisors.
            try {
                await this.exerciseSessionService.putAppointmentToExerciseSession(
                    this.learningResource.exerciseSession.id,
                    this.appointment,
                );
                await this.toastService.showToast(
                    this.translateService.instant('LEARNING_SAVE_SUCCESS_MESSAGE'),
                    IonicColor.success,
                );
            } catch (e) {
                await this.toastService.showToast(
                    this.translateService.instant('APPOINTMENT_CREATED_FAILURE_MESSAGE'),
                    IonicColor.danger,
                );
                await this.returnToList();
                return;
            } finally {
                await this.modalCtrl.dismiss(true);
            }
        }
    }

    private initLearningModuleForm() {
        const title = this.learningResource ? this.learningResource?.exerciseSession?.title : '';
        const description = this.learningResource ? this.learningResource.exerciseSession?.description : '';
        const responsibleRole = this.learningResource
            ? this.learningResource.exerciseSession?.responsibleUserRole
            : this.responsibleSelect[0].value;
        let taskTemplateTitle = this.selectedTaskTemplate ? this.selectedTaskTemplate.title : '';
        let responsible = this.selectedUser ? `${this.selectedUser.lastname}, ${this.selectedUser.firstname}` : '';
        if (
            this.learningResource &&
            this.learningResource.exerciseSession &&
            this.learningResource.exerciseSession.responsibleFullName
        ) {
            responsible = this.learningResource.exerciseSession.responsibleFullName;
        }

        const form = this.form ? this.form.title : '';
        if (this.selectedTaskTemplate) taskTemplateTitle = this.selectedTaskTemplate.title;

        const taskType = ExerciseType.LEARNING;

        const { startTime, endTime } = this.learningResource?.exerciseSession?.appointment ?? {};

        if (startTime) this.isStartTimeInFuture = startTime !== '' ? isFuture(new Date(startTime)) : false;
        if (endTime) this.isEndTimeInPast = endTime !== '' ? isPast(new Date(endTime)) : false;

        const content = this.content.origFileName == null ? '' : this.content.origFileName;
        this.patientLearningForm = this.formBuilder.group({
            taskTemplateTitle: new FormControl(
                { value: taskTemplateTitle, disabled: !this.isEditEnabled },
                Validators.required,
            ),
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, [
                Validators.required,
                Validators.maxLength(255),
            ]),
            description: new FormControl({ value: description, disabled: !this.isEditEnabled }),
            taskType: new FormControl({ value: taskType, disabled: true }, Validators.required),
            form: new FormControl({ value: form, disabled: true }),
            responsibleRole: new FormControl(
                { value: responsibleRole, disabled: !this.isEditEnabled },
                Validators.required,
            ),
            responsible: new FormControl({ value: responsible, disabled: !this.isEditEnabled }),
            hasTimespan: new FormControl({ value: this.hasTimespan, disabled: !this.isEditEnabled }),
            content: new FormControl({ value: content, disabled: !this.isEditEnabled }),
            learningTitle: new FormControl({ value: this.learningTitle, disabled: true }),
            learningContent: new FormControl({ value: this.content, disabled: !this.isEditEnabled }),
            appointment: new FormControl<ExerciseSessionAppointmentDto>({
                value: this.appointment,
                disabled: !this.isEditEnabled,
            }),
        });
    }

    private async enableControllerToUpdate() {
        this.isEditEnabled = true;
        this.hasTimespan = !!this.learningResource.exerciseSession.appointment.startTime;
        if (!this.hasTimespan) this.patientLearningForm.controls.hasTimespan.enable();
        this.patientLearningForm.controls.hasTimespan.setValue(this.hasTimespan);
        this.patientLearningForm.controls.appointment.enable();
    }
}
