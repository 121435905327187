import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as duration from 'duration-fns';
import { mergeMap } from 'rxjs/operators';
import { User } from '../../../auth/entities/user';
import { CommonComponentsModule } from '../../../common/components/common-components.module';
import { ToolbarModalComponent } from '../../../common/components/toolbar-modal/toolbar-modal.component';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { StyleService } from '../../../common/services/style/style.service';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ExerciseType } from '../../entities/exerciseSession';
import { TherapyDto } from '../../entities/therapy';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { TherapiesService } from '../../services/therapies';
import { TherapyTemplatesService } from '../../services/therapy-templates';
import { TherapyTemplateListModalComponent } from '../modal/therapy-template-list-modal/therapy-template-list-modal.component';
import { TaskPlanService } from '../task/task-plan.service';

@Component({
    selector: 'lib-copy-template-modal',
    templateUrl: './copy-template-modal.component.html',
    styleUrls: ['./copy-template-modal.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, ToolbarModalComponent],
})
export class CopyTemplateModalComponent implements OnInit {
    @Input()
    patient: User;
    @Input()
    exerciseType: ExerciseType;

    templateSingularString: string;
    templateSelectString: string;
    templateNoneString: string;
    requiredValidators: Validators;
    therapyForm: FormGroup;
    isEditEnabled: boolean;
    template: TherapyTemplate;
    isMobile: boolean;
    readonly ExerciseType = ExerciseType;
    readonly log: Logger;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private therapiesService: TherapiesService,
        private modalCtrl: ModalController,
        private modalController: ModalController,
        private loggingService: LoggingService,
        private therapyTemplatesService: TherapyTemplatesService,
        private taskPlanService: TaskPlanService,
        private loadingService: LoadingService,
        private styleService: StyleService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isEditEnabled = false;
        this.isMobile = this.styleService.isMobile$;
        this.requiredValidators = Validators.compose([
            Validators.minLength(1),
            Validators.required,
            Validators.maxLength(255),
        ]);
    }

    ngOnInit() {
        this.setStringsBasedOnExerciseType();
        this.initEmptyForm();
    }

    /**
     * Opens a modal (yes, a modal on top of a modal) with a list of therapy templates to select from.
     * If a therapy template is selected, it adjusts the form values accordingly.
     */
    async selectTemplate(): Promise<void> {
        if (this.therapyForm.controls.templateName.disabled) return;
        const modal = await this.modalCtrl.create({
            component: TherapyTemplateListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: this.templateSelectString,
                selectedTherapyTemplates: this.template ? [this.template] : [],
                isMultipleChoice: false,
                anyItem: this.templateNoneString,
                exerciseType: this.exerciseType,
                showOldSelectedExercise: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            this.therapyForm.controls.duration.patchValue(null);
            this.template = data[0];
            this.template = await this.therapyTemplatesService.getTherapyTemplateId(this.template.id);
            this.therapyForm.controls.templateName.patchValue(this.template.title);
            this.therapyForm.controls.title.patchValue(this.template.title);
            if (this.template.duration) this.template.duration = '0';
            if (this.template.duration === '0') {
                this.template.duration = duration.toDays(Number(this.template.duration)).toString();
                this.therapyForm.controls.duration.patchValue(Number(this.template.duration));
                this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
            }
            const exerciseTemplateMaxDuration =
                Math.max(
                    ...this.template.therapyTemplateExercises.map(
                        (i) => duration.toDays(i.startOffset) + duration.toDays(i.endOffset),
                    ),
                ) + 1;
            if (exerciseTemplateMaxDuration >= duration.toDays(Number(this.template.duration))) {
                this.template.duration = duration.toString({ days: exerciseTemplateMaxDuration });
                this.therapyForm.controls.duration.patchValue(duration.toDays(this.template.duration));
                this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
            }
            if (this.exerciseType === ExerciseType.LEARNING || this.exerciseType === ExerciseType.TASK) {
                this.setEndDate(new Date().toISOString());
            }
            if (this.exerciseType === ExerciseType.MIXED) {
                this.therapyForm.controls.duration.patchValue(0);
                const durations = this.template.childTherapyTemplates
                    .map((i) => duration.toDays(i.duration) + (duration.toDays(i.startOffset) - 1))
                    .filter((i) => i !== null);
                if (durations.length > 1) {
                    durations.sort((a, b) => {
                        if (a > b) {
                            return -1;
                        } else if (a < b) {
                            return 1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    this.therapyForm.controls.duration.patchValue(durations[0]);
                    this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
                } else if (durations.length === 1) {
                    this.therapyForm.controls.duration.patchValue(durations[0]);
                    this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
                }
                this.setEndDate(new Date().toISOString());
            }
        }
        if (!this.isEditEnabled) this.toggleFormEdit();
    }

    /**
     * Automatically sets the end date form control based on the selected starting date and duration
     * @param newStartDate - a Date ISO String indicating a start date used to set the end date
     */
    setEndDate(newStartDate: string): void {
        if (this.template?.duration || this.therapyForm.controls.duration.value) {
            /* I'd be interested to know why duration needs to be subtracted 1 here.
             * My guess is that it is needed to have the start date included in the duration as the "first day".
             */
            const duration = this.therapyForm.controls.duration.value - 1;
            this.therapyForm.controls.endDate.patchValue(addDays(new Date(newStartDate), duration).toISOString());
        } else {
            this.therapyForm.controls.endDate.patchValue(newStartDate);
        }
    }

    /**
     * Sends the currently selected therapy template and values to the server for a new therapy to be created
     */
    async saveTherapy() {
        this.toggleFormEdit();
        this.loadingService.startLoadingModal();
        if (this.exerciseType === ExerciseType.MIXED) {
            try {
                const therapy = await this.therapiesService.copyTherapiesFromTemplate(
                    this.template.id,
                    [this.patient.username],
                    null,
                    startOfDay(new Date(this.therapyForm.get('startDate').value)),
                    endOfDay(new Date(this.therapyForm.get('endDate').value)),
                );

                therapy[0] = await this.therapiesService.updateTherapy(therapy[0].id, this.setTherapyDto());
                this.toastService.showToast('PROGRAM.PLAN.ADD_SUCCESS_MESSAGE', IonicColor.success);
                await this.modalController.dismiss(therapy[0]);
            } catch (e) {
                this.log.error(e);
                this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            } finally {
                this.loadingService.stopLoadingModal();
            }
        } else if (this.exerciseType === ExerciseType.TASK) {
            this.taskPlanService
                .create({
                    therapyTemplateId: this.template.id,
                    username: this.patient.username,
                    startDate: startOfDay(new Date(this.therapyForm.get('startDate').value)).toISOString(),
                    endDate: endOfDay(new Date(this.therapyForm.get('endDate').value)).toISOString(),
                })
                .pipe(mergeMap((it) => this.taskPlanService.update(it.id, this.setTherapyDto())))
                .subscribe({
                    next: (therapy) => {
                        this.toastService.showToast('TASK.PLAN.ADD_SUCCESS_MESSAGE', IonicColor.success);
                        this.modalController.dismiss(therapy);
                    },
                    error: (message) => {
                        this.log.error(message);
                        this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
                    },
                    complete: () => this.loadingService.stopLoadingModal(),
                });
        } else if (this.exerciseType === ExerciseType.LEARNING) {
            try {
                let therapy = await this.therapiesService.createTherapyFromTemplateForPatient(
                    this.template.id,
                    this.patient.username,
                    false,
                    startOfDay(new Date(this.therapyForm.get('startDate').value)).toISOString(),
                    endOfDay(new Date(this.therapyForm.get('endDate').value)).toISOString(),
                );
                therapy = await this.therapiesService.updateTherapy(therapy.id, this.setTherapyDto());
                this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                await this.modalController.dismiss(therapy);
            } catch (e) {
                this.log.error(e);
                this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            } finally {
                this.loadingService.stopLoadingModal();
            }
        } else {
            throw new Error(`Exercise type "${this.exerciseType}" not supported by ${this.constructor.name}`);
        }
    }

    /**
     * Dismisses the modal without returning any value
     */
    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    /**
     * Toggles the enabled state of some of the form controls
     */
    private toggleFormEdit(): void {
        this.isEditEnabled = !this.isEditEnabled;
        const titleInput = this.therapyForm.get('title');
        const startDate = this.therapyForm.get('startDate');
        this.isEditEnabled ? titleInput.enable() : titleInput.disable();
        this.isEditEnabled ? startDate.enable() : startDate.disable();
    }

    /**
     * Initializes a form group with empty values for all controls
     */
    private initEmptyForm() {
        this.therapyForm = this.formBuilder.group({
            title: new FormControl({ value: null, disabled: !this.isEditEnabled }, this.requiredValidators),
            startDate: new FormControl<string | null>({
                value: null,
                disabled: !this.isEditEnabled,
            }),
            endDate: new FormControl<string | null>({
                value: null,
                disabled: true,
            }),
            duration: new FormControl<number | null>({
                value: null,
                disabled: true,
            }),
            therapyGoal: new FormControl<string | null>({
                value: null,
                disabled: true,
            }),
            templateName: new FormControl<string | null>(
                {
                    value: null,
                    disabled: false,
                },
                this.requiredValidators,
            ),
        });
    }

    /**
     * Creates a TherapyDto based on the values from the selected TherapyTemplate and the current form values
     * @returns the new TherapyDto with the corresponding attributes
     */
    private setTherapyDto(): TherapyDto {
        const therapyDto = new TherapyDto();
        therapyDto.title = this.therapyForm.get('title').value;
        therapyDto.templateId = this.template.id;
        therapyDto.description = this.template.description;
        therapyDto.startDate = startOfDay(new Date(this.therapyForm.get('startDate').value));
        therapyDto.endDate = endOfDay(new Date(this.therapyForm.get('endDate').value));
        therapyDto.exerciseType = this.exerciseType;
        return therapyDto;
    }

    /**
     * Sets the translation keys for the ui text based on the exercise type
     */
    private setStringsBasedOnExerciseType(): void {
        switch (this.exerciseType) {
            case ExerciseType.TASK:
                this.templateSingularString = 'TASK.TEMPLATE.SINGULAR';
                this.templateSelectString = 'TASK.TEMPLATE.SELECT';
                this.templateNoneString = 'TASK.TEMPLATE.NONE';
                break;
            case ExerciseType.MIXED:
                this.templateSingularString = 'PROGRAM.TEMPLATE.SINGULAR';
                this.templateSelectString = 'PROGRAM.TEMPLATE.SELECT';
                this.templateNoneString = 'PROGRAM.TEMPLATE.NONE';
                break;
            case ExerciseType.LEARNING:
                this.templateSingularString = 'LEARNING.TEMPLATE.SINGULAR';
                this.templateSelectString = 'LEARNING.TEMPLATE.SELECT';
                this.templateNoneString = 'LEARNING.TEMPLATE.NONE';
                break;
            default:
                break;
        }
    }
}
