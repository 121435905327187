// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host(.checkbox-checked) {
  border-color: var(--ion-color-success);
  background: var(--ion-color-success);
}

.state-icon {
  font-size: 25px !important;
  padding-right: 5px !important;
}

.process-icon {
  font-size: 30px;
  padding-right: 5px;
  margin-top: 2px;
}

.state-paragraph {
  align-items: center;
  display: flex;
}

.bullet-point-icon {
  font-size: 5px;
  align-self: center;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/learning/patient-learning/patient-learning-preview/patient-learning-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,oCAAA;AACJ;;AAEA;EACI,0BAAA;EACA,6BAAA;AACJ;;AACA;EACI,eAAA;EACA,kBAAA;EACA,eAAA;AAEJ;;AACA;EACI,mBAAA;EACA,aAAA;AAEJ;;AACA;EACI,cAAA;EACA,kBAAA;EACA,iBAAA;AAEJ","sourcesContent":[":host(.checkbox-checked) {\n    border-color: var(--ion-color-success);\n    background: var(--ion-color-success);\n}\n\n.state-icon {\n    font-size: 25px !important;\n    padding-right: 5px !important;\n}\n.process-icon {\n    font-size: 30px;\n    padding-right: 5px;\n    margin-top: 2px;\n}\n\n.state-paragraph {\n    align-items: center;\n    display: flex;\n}\n\n.bullet-point-icon {\n    font-size: 5px;\n    align-self: center;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
