// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-md .range-knob-handle .range-pin {
  transform: translate3d(0, 0, 0) scale(1);
}

.range-md:not(.range-has-pin) .range-knob-handle .range-knob {
  transform: scale(1);
}

.range-ios .range-knob-handle .range-pin {
  transform: translate3d(0, 0, 0) scale(1);
}

.ion-select {
  max-width: 100%;
  width: 100%;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

* ::-webkit-scrollbar {
  display: none;
}

ion-select {
  max-width: 100%;
}

.select-text {
  min-width: 150px;
}

.ion-label-select {
  max-width: 1px;
}

.text-padding {
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
  -webkit-padding-end: 1em;
          padding-inline-end: 1em;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/feedback/feedback-modal/feedback-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,wCAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,0BAAA;UAAA,yBAAA;EACA,wBAAA;UAAA,uBAAA;AACJ","sourcesContent":[".range-md .range-knob-handle .range-pin {\n    transform: translate3d(0, 0, 0) scale(1);\n}\n\n.range-md:not(.range-has-pin) .range-knob-handle .range-knob {\n    transform: scale(1);\n}\n\n.range-ios .range-knob-handle .range-pin {\n    transform: translate3d(0, 0, 0) scale(1);\n}\n\n.ion-select {\n    max-width: 100%;\n    width: 100%;\n}\n\n.grid-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n* ::-webkit-scrollbar {\n    display: none;\n}\n\nion-select {\n    max-width: 100%;\n}\n\n.select-text {\n    min-width: 150px;\n}\n\n.ion-label-select {\n    max-width: 1px;\n}\n\n.text-padding {\n    padding-inline-start: 1em;\n    padding-inline-end: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
