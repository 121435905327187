import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { StyleService } from '../../services/style/style.service';
import { FilterTag, FilterTagCategory } from '../../entities/filter-tag';
import { LoadingComponent } from '../loading/loading.component';
import { ToolbarModalComponent } from '../toolbar-modal/toolbar-modal.component';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { TranslationModule } from '../../../translation/translation.module';

@Component({
    selector: 'lib-filter-modal',
    templateUrl: './filter-modal.component.html',
    standalone: true,
    imports: [IonicModule, LoadingComponent, ToolbarModalComponent, NgClass, NgIf, TranslationModule, NgForOf],
})
export class FilterModalComponent implements OnInit {
    title: string;
    tagCategories: FilterTagCategory[] = [];
    selectedTags: FilterTag[] = [];
    savedTags: FilterTag[];
    nullTag = { uuid: null } as FilterTag;
    dirty: boolean = false;
    isMobile: boolean;
    enableFilterOptions: boolean = false;
    isComponentInitialised = false;

    constructor(
        private modalController: ModalController,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    ngOnInit() {
        this.setup();
    }

    async saveModal() {
        await this.modalController.dismiss(this.selectedTags).catch(console.error);
    }

    setup() {
        if (this.tagCategories && this.tagCategories.length > 0) this.tagCategories = Array.from(this.tagCategories);
        if (this.selectedTags) {
            if (this.selectedTags.length === 1 && this.selectedTags[0].uuid === null) {
                this.selectedTags.push(this.nullTag);
            } else {
                this.selectedTags = Array.from(this.selectedTags.filter((t) => t.isSelected));
            }
        }
        this.savedTags = Array.from(this.selectedTags);
        this.dirty = false;
        this.isComponentInitialised = true;
    }

    toggle(tag: FilterTag): void {
        if (this.isSelected(tag)) {
            this.removeTagFromSelection(tag);
            tag.isSelected = false;
        } else {
            if (this.isSelected(this.nullTag)) {
                this.selectedTags = [];
            }

            this.selectedTags.push(tag);
            tag.isSelected = true;
        }

        this.isChanged();
    }

    noTags() {
        if (this.isSelected(this.nullTag)) {
            this.removeTagFromSelection(this.nullTag);
        } else {
            this.removeFilter();
            this.selectedTags.push(this.nullTag);
        }

        this.isChanged();
    }

    removeFilter() {
        for (const tagCategories of this.tagCategories) {
            for (const tag of tagCategories.tags) {
                tag.isSelected = false;
            }
        }

        this.selectedTags = [];
        this.isChanged();
    }

    isChanged(): void {
        const savedSet = new Set(this.savedTags);
        const selectedSet = new Set(this.selectedTags);

        this.dirty =
            this.savedTags.length !== this.selectedTags.length || [...savedSet].some((item) => !selectedSet.has(item));
    }

    isSelected(tag: FilterTag): boolean {
        return !!this.selectedTags?.find((it) => it.uuid === tag.uuid);
    }

    private removeTagFromSelection(tag: FilterTag) {
        this.selectedTags?.splice(
            this.selectedTags.findIndex((it) => tag.uuid === it.uuid),
            1,
        );
    }
}
