import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../../api';
import { PaginatedResponse, SortBy, SortOrder } from '../../common/entities/paginated-response';
import { Order, CreateOrderDto, SelectedService } from '../entities/order';
import { LoadingService } from '../../common/services/loading/loading.service';
import { OrderContent } from '../entities/order-content';
import { OrderQuestionnaire } from '../entities/order-questionnaire';
import { MyMedaxFillLink } from '../../my-medax/entities/my-medax-fill-link';
import { Logger, LoggingService } from '../../logging/logging.service';
import { FileContentService } from '../../common/services/content/file-content.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private http: HttpClient,
        private loadingService: LoadingService,
        private fileContentService: FileContentService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getOrders(
        limit?: number,
        offset?: number,
        sortBy?: SortBy,
        sortOrder?: SortOrder,
        withGroups?: boolean,
        withUsers?: boolean,
        withEvents?: boolean,
        patientUsername?: string,
        orderState?: string,
    ): Promise<PaginatedResponse<Order[]>> {
        const url = new URL(`${ApiService.url}orders/`);
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (sortBy) url.searchParams.set('sortBy', sortBy.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder.toString());
        if (withGroups) url.searchParams.set('withGroups', withGroups.toString());
        if (withUsers) url.searchParams.set('withUsers', withUsers.toString());
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        let filterString: string;
        if (patientUsername) {
            filterString = filterString
                ? `${filterString}&PATIENT_USERNAME=${patientUsername}`
                : `PATIENT_USERNAME=${patientUsername}`;
        }
        if (orderState) {
            filterString = filterString ? `${filterString}&ORDER_STATE=${orderState}` : `ORDER_STATE=${orderState}`;
        }
        if (filterString) url.searchParams.set('filter', filterString);
        return await this.http.get<PaginatedResponse<Order[]>>(url.toString()).toPromise();
    }

    async getOrder(
        orderUuid: string,
        withChat?: boolean,
        withEvents?: boolean,
        withGroups?: boolean,
        withUsers?: boolean,
        withContents?: boolean,
        markEventsAsRead?: boolean,
    ): Promise<Order> {
        const url = new URL(`${ApiService.url}orders/${orderUuid}`);
        if (withChat) url.searchParams.set('withChat', withChat.toString());
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        if (withGroups) url.searchParams.set('withGroups', withGroups.toString());
        if (withUsers) url.searchParams.set('withUsers', withUsers.toString());
        if (withContents) url.searchParams.set('withContents', withContents.toString());
        if (markEventsAsRead) url.searchParams.set('markEventsAsRead', markEventsAsRead.toString());
        return await this.http.get<Order>(url.toString()).toPromise();
    }

    async createOrder(requester: string, consultant: string, patient: string) {
        this.loadingService.startLoadingModal();
        const newOrder = new CreateOrderDto(requester, consultant, patient);
        return await this.http
            .post<Order>(`${ApiService.url}orders`, newOrder)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async startProcessingRequest(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/startProcessingRequest`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async saveRequest(
        orderUuid: string,
        orderSubject?: string,
        contactInfo?: string,
        clinicalNotes?: string,
        subjectNotes?: string,
        selectedClientServices?: SelectedService[],
    ) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/saveRequest`);
        const body = {
            subject: orderSubject ? orderSubject : null,
            contactInfo: contactInfo ? contactInfo : null,
            clinicalNotes: clinicalNotes ? clinicalNotes : null,
            subjectNotes: subjectNotes ? subjectNotes : null,
            selectedClientServices: selectedClientServices ? selectedClientServices : null,
        };
        return await this.http
            .post<Order>(url.toString(), body)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async sendRequest(
        orderUuid: string,
        orderSubject: string,
        contactInfo: string,
        clinicalNotes: string,
        subjectNotes?: string,
        selectedClientServices?: SelectedService[],
    ) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/sendRequest`);
        const body = {
            subject: orderSubject,
            contactInfo: contactInfo,
            clinicalNotes: clinicalNotes,
            subjectNotes: subjectNotes ? subjectNotes : null,
            selectedClientServices: selectedClientServices ? selectedClientServices : [],
        };
        return await this.http
            .post<Order>(url.toString(), body)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async acceptRequest(
        orderUuid: string,
        selectedProviderServices?: SelectedService[],
        justifyingIndicationGiven?: boolean,
        justifyingIndicationNotes?: string,
    ) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/acceptRequest`);
        const body = {
            selectedProviderServices: selectedProviderServices ? selectedProviderServices : [],
            justifyingIndicationGiven: justifyingIndicationGiven ? justifyingIndicationGiven : false,
            justifyingIndicationNotes: justifyingIndicationNotes ? justifyingIndicationNotes : null,
        };
        return await this.http
            .post<Order>(url.toString(), body)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async finishExamination(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/finishExamination`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async createFinding(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/createFinding`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async approveFinding(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/approveFinding`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async finishOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/finishOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/deleteOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async declineOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/declineOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async revokeOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/revokeOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async cancelOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/cancelOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async reopenOrder(orderUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/transition/reopenOrder`);
        return await this.http
            .post<Order>(url.toString(), null)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async uploadFile(orderUuid: string, asset): Promise<OrderContent> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/contents`);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const options = { headers, withCredentials: true };
        return await this.http
            .post<OrderContent>(url.toString(), asset, options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteFile(orderUuid: string, orderContentId: string): Promise<void> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}orders/${orderUuid}/contents/${orderContentId}`);
        await this.http
            .delete(url.toString(), { ...ApiService.options, responseType: 'text' })
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async getFileDownloadUrl(orderUuid: string, orderContent: OrderContent) {
        const url = new URL(`${ApiService.url}orders/${orderUuid}/contents/${orderContent.uuid}/download`);
        await this.fileContentService.openObjectURLinNewWindow(url.toString());
    }

    async getOrderQuestionnaires(orderUuid: string): Promise<PaginatedResponse<OrderQuestionnaire[]>> {
        const url = new URL(`${ApiService.url}orders/${orderUuid}/questionnaires`);
        return await this.http.get<PaginatedResponse<OrderQuestionnaire[]>>(url.toString()).toPromise();
    }

    async getFillLink(
        orderUuid: string,
        orderQuestionnaireUuid: string,
        returnUri?: string,
        prefill?: boolean,
        prefillSubmissions?: string[],
        submissionVersioningUuid?: string,
    ) {
        const url = new URL(`${ApiService.url}orders/${orderUuid}/questionnaires/${orderQuestionnaireUuid}/fillLink`);
        if (returnUri) url.searchParams.set('redirectAfterFillUri', encodeURI(returnUri));
        if (prefill) url.searchParams.set('prefill', prefill.toString());
        if (prefillSubmissions) url.searchParams.set('prefillSubmissions', prefillSubmissions.toString());
        if (submissionVersioningUuid) {
            url.searchParams.set('submissionVersioningUuid', submissionVersioningUuid.toString());
        }
        return await this.http.get<MyMedaxFillLink>(url.toString()).toPromise();
    }
}
