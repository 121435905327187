import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ExerciseSession,
    ExerciseSessionState,
    ExerciseSessionUserState,
    ExerciseSubType,
    ExerciseType,
} from '../../../entities/exerciseSession';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { ModalController, PopoverController } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../../auth/services';
import { QuestionnaireTemplate } from '../../../../my-medax/entities/questionnaire-template';
import { TherapyMyMedaxService } from '../../../services/therapy-my-medax';
import { QuestionnaireType } from '../../../../my-medax/entities/questionnaire-type';
import { MyMedaxQuestionnaireSubmissionComponent } from '../../../../my-medax/components/my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { differenceInMinutes, isFuture } from 'date-fns';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExerciseSessionsService } from '../../../services/exercise-sessions';
import { MyMedaxQuestionnaireSubmission } from '../../../../my-medax/entities/my-medax-questionnaire-submission';
import { TaskFormComponent } from '../task-form/task-form.component';
import { ActionType } from '../../../../table/entities/table';
import { CurafidaPopoverSelectionComponent } from '../../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { MimeTypeCurafida } from '../../../../common/entities/mime.type';
import { ParticipationService } from '../participation/participation.service';
import { toString } from 'duration-fns';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../../common/services/modal';
import { ExerciseSessionStateChange } from '../../../entities/exerciseSession/exercise-session-state-change';
import { UserTaskPreview } from '../user-task-preview';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireListModalComponent } from '../../../../my-medax/components/questionnaire-list-modal/questionnaire-list-modal.component';
import { TaskActionLinkName, TaskResource } from '../task.resource';
import { TaskActionsPipe } from '../task-actions.pipe';
import { AuthorizationPipe } from '../../../../hateoas/authorization.pipe';
import { BrowserNavigationService } from '../../../../common/services/browser-navigation/browser-navigation.service';
import { TaskService } from '../../../services/task/task.service';
import { ArticulateContentsService } from '../../../services/articulate/articulate-contents.service';
import { IconAdapterElement } from '../../../../table/components/table-adapter/icon-adapter.component';
import { LoadingService } from '../../../../common/services/loading/loading.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-caregiver-task-preview',
    templateUrl: './caregiver-task-preview.component.html',
    styleUrls: ['./caregiver-task-preview.component.scss'],
})
export class CaregiverTaskPreviewComponent extends UserTaskPreview implements OnInit, OnDestroy {
    @ViewChild('viewSelect') viewSelect: HTMLIonSelectElement;
    @Input()
    taskResource: TaskResource;
    @Input()
    isMobile = false;
    @Input()
    patient: User;
    title = 'Aufgabe';
    ExerciseSessionState = ExerciseSessionState;
    ExerciseSessionUserState = ExerciseSessionUserState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    fromMyMedax = false;
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isButtonDisable = false;
    isModalFullSize = false;
    showCalendarEvent = false;
    showAppointment = false;
    showLearning = false;
    ExerciseSubType = ExerciseSubType;
    showContents = false;
    closeTaskStateChange: ExerciseSessionStateChange;
    protected readonly log: Logger;
    protected readonly ExerciseType = ExerciseType;
    private element: HTMLElement;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected toastService: ToastService,
        private authService: CurafidaAuthService,
        private styleService: StyleService,
        protected taskService: TaskService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private modalCtrl: ModalController,
        protected loggingService: LoggingService,
        private exerciseSessionService: ExerciseSessionsService,
        private popoverController: PopoverController,
        readonly participationService: ParticipationService,
        protected modalAlertService: ModalAlertService,
        protected translateService: TranslateService,
        protected readonly taskActionsPipe: TaskActionsPipe,
        protected readonly authorizationPipe: AuthorizationPipe,
        private browser: BrowserNavigationService,
        protected userExerciseSessionsService: UserExerciseSessionsService,
        readonly articulateContentsService: ArticulateContentsService,
        private readonly loadingService: LoadingService,
    ) {
        super(
            taskService,
            userExerciseSessionsService,
            toastService,
            modalAlertService,
            loggingService,
            translateService,
            authorizationPipe,
        );
    }

    async ngOnInit() {
        await this.initComponent();
        if (this.taskResource.exerciseSubType === ExerciseSubType.QUESTIONNAIRE) {
            this.myMedaxQuestionnaireSubmissions =
                await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(this.taskResource);
        }
        if (this.taskResource.exerciseSubType === ExerciseSubType.FREETEXT) await this.initFreeTextContent();
    }

    ngOnDestroy(): void {
        this.participationService.clear();
    }

    isStartInFuture(exerciseSession: ExerciseSession | TaskResource) {
        return exerciseSession.appointment?.startTime !== ''
            ? isFuture(new Date(exerciseSession.appointment?.startTime))
            : false;
    }

    setCssClass() {
        this.element = document.getElementById('CaregiverTaskPreviewComponent');
        if (this.isModalFullSize) {
            this.element?.classList.add('task-preview-full-information-modal');
            this.element?.classList.remove('task-preview-modal');
        } else {
            if (this.taskResource?.exerciseSubType === ExerciseSubType.FREETEXT) {
                this.element?.classList.add('free-text-task-preview-modal');
            } else {
                this.element?.classList.add('task-preview-modal');
            }
            this.element?.classList.remove('task-preview-full-information-modal');
        }
    }

    async cancelTask() {
        if (
            !(
                this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED ||
                this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE
            )
        ) {
            await this.toastService.showToast(this.translateService.instant('CANCEL_TASK_FAILURE'), IonicColor.danger);
        }
        try {
            const modalConfig = new ModalConfig();
            modalConfig.modalTyp = ModalTyp.INFORMATION;
            modalConfig.title = `„${this.taskResource.title}“ ${this.translateService.instant('CANCEL_LOWER')}`;
            modalConfig.titleIcon = 'warning-outline';
            modalConfig.description = this.translateService.instant('CANCEL_TASK_PROMPT');
            modalConfig.buttonLeft = new ButtonConfig();
            modalConfig.buttonLeft.buttonText = 'BACK';
            modalConfig.buttonLeft.buttonColor = 'primary';
            modalConfig.buttonRight = new ButtonConfig();
            modalConfig.buttonRight.buttonText = this.translateService.instant('CANCEL_TASK');
            modalConfig.buttonRight.buttonColor = 'danger';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'right') {
                try {
                    await this.exerciseSessionService.postTaskAction(
                        this.taskResource,
                        TaskActionLinkName.RESPONSIBLE_CANCEL,
                    );
                    this.taskResource.exerciseSessionUserState = ExerciseSessionUserState.CANCELLED;
                    await this.toastService.showToast(
                        this.translateService.instant('CANCEL_TASK_SUCCESS'),
                        IonicColor.success,
                    );
                } catch (err) {
                    this.log.error('Error in cancel task', err);
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                } finally {
                    await this.dismissModal();
                }
            }
        } catch (e) {
            this.log.error('Error in cancelTask', e);
            await this.toastService.showToast(this.translateService.instant('CANCEL_TASK_FAILURE'), IonicColor.danger);
        }
    }

    async initComponent() {
        if (!this.taskResource) {
            this.log.error('Task is missing, please provide it as a param.');
            return;
        }
        this.taskResource = await this.taskService.fetchTask(this.taskResource.id);
        this.title = this.taskResource.title;
        this.setCssClass();
        this.userRoles = this.authService.getSession()?.user.roles;
        if (this.taskResource.calendarEvent) {
            this.showCalendarEvent = true;
            this.taskResource.duration = toString({
                minutes: differenceInMinutes(
                    new Date(this.taskResource.calendarEvent.endTime),
                    new Date(this.taskResource.calendarEvent.startTime),
                ),
            });
        }
        if (this.taskResource.appointment?.startTime || this.taskResource.appointment?.endTime) {
            this.showAppointment = true;
        }

        if (!this.taskResource.responsibleFullName && this.taskResource.responsibility.role === UserRoles.PATIENT) {
            this.taskResource.responsibleFullName = `${this.patient.lastname}, ${this.patient.firstname}`;
        }

        if (
            !this.taskResource.responsibleFullName &&
            (this.taskResource.responsibility.role === UserRoles.CAREGIVER ||
                this.taskResource.responsibility.role === UserRoles.SUPERVISOR) &&
            this.taskResource.responsibility.person
        ) {
            this.taskResource.responsibleFullName = `${this.taskResource.responsibility.person.lastname}, ${this.taskResource.responsibility.person.firstname}`;
        }

        if (this.taskResource.contents.length > 0) {
            this.taskResource.contents = this.taskResource.contents.filter(
                (i) => i.mimeType !== MimeTypeCurafida.MY_MEDAX,
            );
            if (this.taskResource.contents.length > 0) this.showContents = true;
        }
        if (
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.FINISHED ||
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.CANCELLED ||
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PATIENT_CANCELLED
        ) {
            this.closeTaskStateChange = this.taskResource.stateChanges.find(
                (x) =>
                    x.newState === ExerciseSessionState.FINISHED ||
                    x.newState === ExerciseSessionState.CANCELLED ||
                    x.newState === ExerciseSessionState.PATIENT_CANCELLED,
            );
        }
        if (this.taskResource.exerciseSubType === ExerciseSubType.LEARNING) {
            this.isModalFullSize = true;
            this.showLearning = true;
            this.articulateContentsService.init(this.taskResource);
        }
    }

    async openMyMedax() {
        let returnUri = window.location.href;
        returnUri = returnUri.slice(-1) === '/' ? returnUri.slice(0, -1) : returnUri;
        if (returnUri.includes('?')) {
            returnUri = returnUri + `&fromMyMedax=true&taskId=${this.taskResource.id}`;
        } else {
            returnUri = returnUri + `?fromMyMedax=true&taskId=${this.taskResource.id}`;
        }
        if (this.styleService.isMobile$) {
            returnUri = null;
        }
        const fillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
            this.patient.username,
            this.taskResource.id.toString(),
            returnUri,
            QuestionnaireType.TASK,
        );
        this.browser.openTargetSelf(fillLink.url);
    }

    async showSubmissionVersions() {
        if (this.myMedaxQuestionnaireSubmissions?.length > 0) {
            const modal = await this.modalCtrl.create({
                component: QuestionnaireListModalComponent,
                backdropDismiss: true,
                cssClass: 'task-preview-modal',
                componentProps: {
                    myMedaxQuestionnaireSubmissions: this.myMedaxQuestionnaireSubmissions,
                    questionnaireTitle: this.myMedaxQuestionnaireSubmissions[0].title,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            return data;
        }
    }

    async showQuestionnaireModal() {
        const modal = await this.modalCtrl.create({
            component: MyMedaxQuestionnaireSubmissionComponent,
            backdropDismiss: true,
            componentProps: {
                myMedaxQuestionnaireSubmission: this.myMedaxQuestionnaireSubmissions[0],
                allowFillNewVersion: this.authorizationPipe.transform(this.taskResource, 'myMedax', 'read'),
                taskResource: this.taskResource,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    async finishTask() {
        this.loadingService.startLoadingModal();
        try {
            if (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED) {
                await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_ACTIVATE);
            }
            await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_FINISH);
            this.taskResource.exerciseSessionUserState = ExerciseSessionUserState.FINISHED;
            await this.toastService.showToast(this.translateService.instant('FINISH_TASK_SUCCESS'), IonicColor.success);
        } catch (e) {
            this.log.error('Error in finishTask', e);
            await this.toastService.showToast(this.translateService.instant('FINISH_TASK_FAILURE'), IonicColor.danger);
        } finally {
            this.loadingService.stopLoadingModal();
            await this.dismissModal();
        }
    }

    async dismissModal() {
        const queryParams: Params = { fromMyMedax: null, taskId: null };
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        await this.modalCtrl.dismiss('true');
    }

    async setView() {
        this.isModalFullSize = !this.isModalFullSize;
        this.setCssClass();
        if (this.isModalFullSize && this.taskResource?._links) {
            this.participationService.init(this.taskResource);
        }
    }

    async editTask() {
        let css;

        if (this.isModalFullSize) {
            css = 'task-preview-full-information-modal';
        } else {
            if (this.taskResource?.exerciseSubType === ExerciseSubType.FREETEXT) {
                css = 'free-text-task-preview-modal';
            } else {
                css = 'full-width-modal';
            }
        }
        const modal = await this.modalCtrl.create({
            component: TaskFormComponent,
            cssClass: css,
            componentProps: {
                task: this.taskResource,
                isNew: false,
                editCalendar: true,
                isMobile: this.isMobile,
                patient: this.patient,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            await this.initComponent();
        }
    }

    async presentActionButtons(event: MouseEvent) {
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            translucent: true,
            event: event,
            componentProps: {
                actionItems: this.taskActionsPipe.transform(this.taskResource),
            },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data === ActionType.MODAL) await this.showSubmissionVersions();
        if (data === ActionType.UPDATE) await this.editTask();
        if (data === ActionType.CANCELED) await this.cancelTask();
        if (data === ActionType.FILL_IN_FORM) await this.openMyMedax();
    }

    isExerciseActiveOrFinished() {
        return (
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE ||
            (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED &&
                !this.isStartInFuture(this.taskResource)) ||
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.FINISHED
        );
    }

    getIconGroups(icons: IconAdapterElement[]): IconAdapterElement[][] {
        const iconGroups: IconAdapterElement[][] = [];
        const iconsPerLine = 20;
        for (let i = 0; i < icons.length; i += iconsPerLine) {
            iconGroups.push(icons.slice(i, i + iconsPerLine));
        }
        return iconGroups;
    }
}
