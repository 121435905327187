import { Pipe, PipeTransform } from '@angular/core';
import { TimelineState } from './timeline.entity';

export interface PatientJournalItemIconConfig {
    name: string;
    background: string;
}

@Pipe({
    standalone: true,
    name: 'timelineItemIconConfig',
})
export class TimelineItemIconConfigPipe implements PipeTransform {
    transform(state: TimelineState): PatientJournalItemIconConfig {
        if (state === TimelineState.SUCCESS) {
            return { name: 'checkmark-circle', background: 'success' };
        } else if (state === TimelineState.WAITING) {
            return { name: 'ellipse', background: 'primary' };
        } else if (state === TimelineState.FAILURE) {
            return { name: 'close-circle', background: 'danger' };
        }
        return { name: 'alert-circle', background: 'primary' };
    }
}
