import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { plainToClass } from 'class-transformer';
import {
    Connection,
    ConnectionOptions,
    createConnection,
    FindManyOptions,
    FindOneOptions,
    getRepository,
    IsNull,
} from 'typeorm';
import { CurafidaAuthService } from '../../../auth/services';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { Logger, LoggingService, LogLevel } from '../../../logging/logging.service';
import { CurafidaQuestionnaire } from '../../../questionnaire/entities/curafida-questionnaire';
import { CurafidaQuestionnaireType } from '../../../questionnaire/entities/curafida-questionnaire-type';
import { BluetoothSerialDevice } from '../../entities/bluetooth-models/bluetooth-serial-device';
import { ConnectionType, DeviceClass, DeviceConnectionInfo, MeasurementDevice } from '../../entities/models/Device';
import { Examination, Measurement, Patient } from '../../entities/models/Examination';
import { MeasurementType } from '../../entities/models/measurement-type';
import {
    CosinussMeasurementType,
    MeasurementContent,
    MeasurementContentBloodPressure,
    MeasurementContentBloodSugarLevel,
    MeasurementContentBodyWeight,
    MeasurementContentECG,
    MeasurementContentFhirQuestionnaire,
    MeasurementContentHeight,
    MeasurementContentNote,
    MeasurementContentOtoscope,
    MeasurementContentPulse,
    MeasurementContentSpirometry,
    MeasurementContentSPO2,
    MeasurementContentStethoscope,
    MeasurementContentTemperature,
    MeasurementContentWoundDoc,
    PulseOximeterMeasurementType,
} from '../../entities/models/MeasurementContent';
import { InitScheme1589286032424 } from '../../migrations/1589286032424-InitScheme';
import { AddPdfContentUuid1591191931576 } from '../../migrations/1591191931576-AddPdfContentUuid';
import { AddIsExportPending1591699841611 } from '../../migrations/1591699841611-Add-isExportPending';
import { AddCreatedAtLocal1594910882080 } from '../../migrations/1594910882080-Add-createdAtLocal';
import { AddTenantId1598278812130 } from '../../migrations/1598278812130-Add-tenantId';
import { AddCurafidaQuestionnaire1631018203088 } from '../../migrations/1631018203088-Add-CurafidaQuestionnaire';
import { AddActiveFlagToQuestionnaire1645171018030 } from '../../migrations/1645171018030-Add-active-flag-to-questionnaire';
import { FileStorageService } from '../file-storage/file-storage.service';

@Injectable({
    providedIn: 'root',
})
export class DbService {
    protected readonly log: Logger;
    private _dbConnection: Connection;

    constructor(
        private platform: Platform,
        private authService: CurafidaAuthService,
        private fileStorageService: FileStorageService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel(LogLevel.INFO);
        this.log.debug('DbService startet');
    }

    public async getDbConnection(migrate = true): Promise<Connection> {
        this.log.debug('geDbCOnnection');
        if (!this._dbConnection) {
            this._dbConnection = await this.openConnection(migrate);
        }
        return this._dbConnection;
    }

    /**
     * Returns a paginated response of patients.
     * Including for each patient their latest examination or none.
     * Also the measurements of the examination are included.
     *
     * Ordering is always by examination.timestamp
     */
    async getPatientsWithLatestExaminationPaginated(offset = 0, limit = 10): Promise<PaginatedResponse<Patient[]>> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            throw new Error('No tenant id is set!');
        }
        // It seems TypeORM does not work with the combination of subqueries,
        // relations and mapping (letJoinAndMapOne with subquery)
        // Therefore we will first SELECT all latest examinations from patients
        const latestExams = await getRepository(Examination)
            .createQueryBuilder('ex')
            .addSelect('max(ex."timestamp")')
            .where('ex.tenantId = :tenantid', { tenantid: tenantId })
            .groupBy('ex.patientUuid')
            .orderBy('ex.timestamp', 'DESC')
            .skip(offset)
            .take(limit)
            .getMany();
        // Now we will read all patients together with their latest examinations
        // and measurements with the help of the previous query results
        const allPatientsWithLatestExsQuery = getRepository(Patient)
            .createQueryBuilder('pat')
            .leftJoinAndMapOne(
                'pat.latestExam',
                Examination,
                'ex',
                'ex.patientUuid = pat.uuid AND ex.uuid IN (:...latestexuuids)',
                { latestexuuids: latestExams.map((x) => x.uuid) },
            )
            .leftJoinAndMapMany('pat.latestExamMeas', Measurement, 'meas', 'meas.examinationUuid = ex.uuid')
            .where('pat.tenantId = :tenantid', { tenantid: tenantId })
            .orderBy('ex.timestamp', 'DESC')
            .skip(offset)
            .take(limit);
        const allPatientsWithLatestExs = await allPatientsWithLatestExsQuery.getMany();
        for (const ex of allPatientsWithLatestExs) {
            // TypeORM is not capable of mapping to pat.examinations AND pat.examinations.measurements
            // together. Thats why this loop is neccessary to correct the object structure.
            ex.examinations = [(ex as any)?.latestExam];
            if (ex.examinations[0]) {
                ex.examinations[0].measurements = (ex as any)?.latestExamMeas;
            }
            delete (ex as any).latestExam;
            delete (ex as any).latestExamMeas;
        }
        // Now construct a paginated response object
        return {
            items: allPatientsWithLatestExs,
            count: allPatientsWithLatestExs.length,
            total: await getRepository(Patient).count({ where: { tenantId } }),
            limit,
            offset,
            orderBy: [{ column: 'Examination.timestamp' }],
        } as PaginatedResponse<Patient[]>;
    }

    async getPaginatedPatients(nested = false, offset = 0, limit = 10): Promise<Patient[]> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return [];
        }
        const findOptions: FindManyOptions = {
            where: { tenantId },
            skip: offset,
            take: limit,
        };
        if (nested) {
            findOptions.relations = ['examinations', 'examinations.measurements'];
        }
        return getRepository(Patient).find(findOptions);
    }

    async getPatients(nested = false): Promise<Patient[]> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return [];
        }
        const findOptions: FindManyOptions = {
            where: { tenantId },
        };
        if (nested) {
            findOptions.relations = ['examinations', 'examinations.measurements'];
        }
        return getRepository(Patient).find(findOptions);
    }

    async getPatient(patientUuid: string, nested = false): Promise<Patient> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        const findOptions: FindOneOptions = {
            where: { uuid: patientUuid, tenantId },
        };
        if (nested) {
            findOptions.relations = ['examinations', 'examinations.measurements'];
        }
        return await getRepository(Patient).findOne(findOptions);
    }

    async getExaminations(nested = false): Promise<Examination[]> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return [];
        }
        const findOptions: FindManyOptions = {
            where: { tenantId },
        };
        if (nested) {
            findOptions.relations = ['measurements'];
        }
        return await getRepository(Examination).find(findOptions);
    }

    /**
     * Search for a patient with a given string input patientIdUserInput
     * equal to pvsId or alternativeName.
     * @returns either the found patient or undefined
     */
    async getPatientByUserInputId(patientIdUserInput: string): Promise<Patient> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        const findOptions: FindOneOptions<Patient> = {
            where: [
                { pvsId: patientIdUserInput, tenantId },
                { alternativeName: patientIdUserInput, tenantId },
            ],
        };
        return await getRepository(Patient).findOne(findOptions);
    }

    async createPatient(patient: Patient): Promise<Patient> {
        if (!patient.tenantId) {
            throw new Error('tenantId is null');
        }
        if (!patient.pvsId && !patient.alternativeName && !patient.firstname && !patient.lastname) {
            throw new Error('Invalid patient with pvsId, alternativeName, firstname and lastname equal null');
        }
        return await getRepository(Patient).save(patient);
    }

    async getCurafidaQuestionnaires(type: CurafidaQuestionnaireType): Promise<CurafidaQuestionnaire[]> {
        return await getRepository(CurafidaQuestionnaire).find({ where: { curafidaQuestionnaireType: type } });
    }

    async getCurafidaQuestionnaire(uuid: string): Promise<CurafidaQuestionnaire> {
        return await getRepository(CurafidaQuestionnaire).findOne({ where: { uuid } });
    }

    async getExamination(examinationUuid: string): Promise<Examination> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        return await getRepository(Examination).findOne({
            where: { uuid: examinationUuid, tenantId },
            relations: ['measurements', 'patient'],
        });
    }

    async createExamination(creator: string, patientUuid?: string): Promise<Examination> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        let examination = new Examination();
        examination.creator = creator;
        if (patientUuid) {
            examination.patient = await this.getPatient(patientUuid);
        }
        examination.timestamp = new Date().toISOString();
        examination.isFinished = false;
        examination.tenantId = tenantId;
        examination = await getRepository(Examination).save(examination);
        return this.getExamination(examination.uuid);
    }

    async getMeasurementFromUnfinishedExamination(patient: string, measType: MeasurementType): Promise<Measurement> {
        const examination = await this.getLatestUnfinishedExamination(patient);
        if (!examination) {
            // TODO launch error
            this.log.info('No old examination found');
        } else {
            return examination.measurements.find((measure) => measure.type === measType);
        }
    }

    async getLatestUnfinishedExamination(patientUuid?: string): Promise<Examination> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        const session = this.authService.getSession();
        if (!patientUuid) {
            patientUuid = null;
        }
        let examination: Examination;
        examination = await getRepository(Examination).findOne({
            where: { isFinished: false, patientUuid: patientUuid, tenantId: tenantId },
            relations: ['measurements', 'patient'],
        });
        if (examination == null) {
            examination = new Examination();
            examination.creator = session.user.username;
            examination.timestamp = new Date().toISOString();
            examination.measurements = [];
            examination.isFinished = false;
            examination.tenantId = tenantId;
            if (patientUuid) {
                examination.patient = await this.getPatient(patientUuid);
            }
            examination = await this.saveExamination(examination);
            this.log.info('Creating an new examination: ', examination);
        }
        return examination;
    }

    async upsertMeasurementIntoExamination(examination: Examination, measurement: Measurement): Promise<void> {
        if (examination.isFinished) {
            throw new Error('Examination is already finished!');
        }
        const existingMeasurement = examination.measurements.find(
            (meas: Measurement) => meas.type === measurement.type,
        );
        if (existingMeasurement !== undefined) {
            measurement.uuid = existingMeasurement.uuid;
        } else {
            examination.measurements.push(measurement);
            await getRepository('Examination').save(examination);
        }
        try {
            this.log.debug('upsertMeasurementIntoExamination:', measurement);
            await getRepository('Measurement').save(measurement);
        } catch (error) {
            this.log.error(error);
        }
    }

    async saveExamination(examination: Examination): Promise<Examination> {
        const tenantId = await this.authService.getTenantId();
        if (!tenantId) {
            return null;
        }
        examination.tenantId = tenantId;
        if (examination.patient) {
            examination.patient.tenantId = tenantId;
            await getRepository(Patient).save(examination.patient);
        }
        return await getRepository(Examination).save(examination);
    }

    // TODO @Paul weisst du, was macht diese Funktion? oder vielleicht passt der Name nicht?
    async deleteMeasurementContentFromExamination(
        examination: Examination,
        measurementType: MeasurementType,
    ): Promise<Examination> {
        if (examination.measurements.find((i) => i.type === measurementType)) {
            examination.measurements = examination.measurements.filter((e) => e.type !== measurementType);
        }
        await getRepository(Examination).save(examination);
        return this.getExamination(examination.uuid);
    }

    async deleteMeasurementContentsFromExamination(examination: Examination): Promise<Examination> {
        await this.removeFilesFromExamination(examination);
        examination = this.setMeasurementContentToNull(examination);
        await getRepository(Examination).save(examination);
        return this.getExamination(examination.uuid);
    }

    setMeasurementContentToNull(examination: Examination): Examination {
        for (const measurement of examination.measurements) {
            if (measurement.type === MeasurementType.FHIR_QUESTIONNAIRE) {
                this.clearQuestionnaireContent(measurement);
            } else {
                measurement.content = null;
            }
        }
        return examination;
    }

    measurementContentJsonToClass(type: MeasurementType, measContent: MeasurementContent): MeasurementContent {
        if (!measContent) {
            return null;
        }
        switch (type) {
            case MeasurementType.BLOOD_PRESSURE:
                return plainToClass(MeasurementContentBloodPressure, measContent);
            case MeasurementType.BLOOD_SUGAR_LEVEL:
                return plainToClass(MeasurementContentBloodSugarLevel, measContent);
            case MeasurementType.BODY_WEIGHT:
                return plainToClass(MeasurementContentBodyWeight, measContent);
            case MeasurementType.ECG:
                return plainToClass(MeasurementContentECG, measContent);
            case MeasurementType.HEIGHT:
                return plainToClass(MeasurementContentHeight, measContent);
            case MeasurementType.OTOSCOPE:
                return plainToClass(MeasurementContentOtoscope, measContent);
            case MeasurementType.EMPTY:
                return plainToClass(MeasurementContentNote, measContent);
            case MeasurementType.SPIROMETRY:
                return plainToClass(MeasurementContentSpirometry, measContent);
            case MeasurementType.SPO2:
                return plainToClass(MeasurementContentSPO2, measContent);
            case MeasurementType.STETHOSCOPE:
                return plainToClass(MeasurementContentStethoscope, measContent);
            case MeasurementType.TEMPERATURE:
                return plainToClass(MeasurementContentTemperature, measContent);
            case MeasurementType.FHIR_QUESTIONNAIRE:
                return plainToClass(MeasurementContentFhirQuestionnaire, measContent);
            case MeasurementType.WOUND_DOC:
                return plainToClass(MeasurementContentWoundDoc, measContent);
            case MeasurementType.PULSE:
                return plainToClass(MeasurementContentPulse, measContent);
            default:
                throw Error('Error converting Measurement Content to specific type');
            //     return measContent
        }
    }

    /**
     * Deletes an Examination with all related Measurements and Files
     */
    async deleteExamination(examination: Examination): Promise<Examination> {
        await this.removeFilesFromExamination(examination);
        await getRepository(Measurement).remove(examination.measurements);
        return await getRepository(Examination).remove(examination);
    }

    /**
     * Removes a Patient from database with all related data.
     * This operation also removes related Examinations, Measurements and Files.
     * @param patientUuid patient identifier
     */
    async deletePatient(patientUuid: string): Promise<void> {
        const patient = await this.getPatient(patientUuid, true);
        this.log.info(patient);
        for (const examination of patient.examinations) {
            await this.deleteExamination(examination);
        }
        await getRepository(Patient).remove(patient);
    }

    async assignExaminationToPatient(examination: Examination, patientUuid: string): Promise<Examination> {
        examination.patient = await getRepository(Patient).findOne({ where: { uuid: patientUuid } });
        await this.saveExamination(examination);
        return await this.getExamination(examination.uuid);
    }

    async unassignPatientFromExamination(examinationUuid: string): Promise<Examination> {
        const examination = await getRepository(Examination).findOne({ where: { uuid: examinationUuid } });
        examination.patient = null;
        await this.saveExamination(examination);
        return await this.getExamination(examination.uuid);
    }

    /**
     * Disable later needed for introducing a tenant id
     */
    async setTenantIdIfNull(): Promise<void> {
        let tenantId: string;
        try {
            tenantId = await this.authService.getTenantId();
        } catch (error) {
            return;
        }
        let examinations = await getRepository(Examination).find({
            where: { tenantId: IsNull() },
        });
        examinations = examinations.map((x: Examination) => {
            x.tenantId = tenantId;
            return x;
        });
        await getRepository(Examination).save(examinations);
        let patients = await getRepository(Patient).find({
            where: { tenantId: IsNull() },
        });
        patients = patients.map((pat: Patient) => {
            pat.tenantId = tenantId;
            return pat;
        });
        await getRepository(Patient).save(patients);
    }

    async saveDevice(device: DeviceClass): Promise<DeviceClass> {
        const storedDevice = await getRepository(DeviceClass).findOne({
            where: {
                measurementType: device.measurementType,
            },
        });
        if (storedDevice) {
            device.uuid = storedDevice.uuid;
        }
        return await getRepository(DeviceClass).save(device);
    }

    async getDevice(type: MeasurementType | CosinussMeasurementType): Promise<DeviceClass> {
        return await getRepository(DeviceClass).findOne({
            where: { measurementType: type },
        });
    }

    async getDevices(): Promise<DeviceClass[]> {
        return await getRepository(DeviceClass).find();
    }

    async saveDeviceFromBTSerialScanning(
        bluetoothSerialDevice: BluetoothSerialDevice,
        type: MeasurementType | CosinussMeasurementType | PulseOximeterMeasurementType,
    ): Promise<DeviceClass> {
        const device = new DeviceClass();
        device.macAddress = bluetoothSerialDevice.address;
        device.name = bluetoothSerialDevice.name;
        device.connectionType = ConnectionType.BLUETOOTH_CLASSIC;
        device.measurementType = type;
        device.vendor = bluetoothSerialDevice.name;
        device.model = bluetoothSerialDevice.name;
        return await this.saveDevice(device);
    }

    async saveLocallyBTSerialDeviceInformation(
        bluetoothSerialDevice: BluetoothSerialDevice,
        deviceConnectionInfo: DeviceConnectionInfo,
    ): Promise<DeviceClass> {
        const device = deviceConnectionInfo.deviceClass;
        this.log.debug('device:', device);
        return await this.saveDevice(device);
    }

    /**
     * Run pending typeorm migrations.
     */
    async runDatabaseMigrations(connection: Connection): Promise<void> {
        const isMigrationPending = await connection.showMigrations();
        if (isMigrationPending) {
            this.log.info(`Run pending database migrations`);
            await connection.runMigrations();
        }
    }

    async deleteDevice(device: DeviceClass): Promise<DeviceClass> {
        this.log.info('device to be deleted:', device);
        return await getRepository(DeviceClass).remove(device);
    }

    async resetLocalDatabase(migrate = true, sure = false, reallySure = false): Promise<void> {
        if (!sure || !reallySure) {
            return;
        }
        const conn = await this.getDbConnection(false);
        await conn.dropDatabase();
        await conn.close();
        this._dbConnection = null;
        await this.getDbConnection(migrate);
    }

    public async convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });
    }

    async deleteMeasurementTypeFromExamination(
        examinationUuid: string,
        measurementType: MeasurementType,
    ): Promise<Examination> {
        const ex = await this.getExamination(examinationUuid);
        ex.measurements = ex?.measurements.filter((meas: Measurement) => {
            // TODO Delete measurement files
            return meas?.type !== measurementType;
        });
        await this.saveExamination(ex);
        return ex;
    }

    private clearQuestionnaireContent(measurement: Measurement) {
        if (measurement.type !== MeasurementType.FHIR_QUESTIONNAIRE) return;
        const questionnaireMeasurementContent = measurement.content as MeasurementContentFhirQuestionnaire;
        for (const questionnaireResponse of questionnaireMeasurementContent.measurementQuestionnaireResponses) {
            questionnaireResponse.questionnaireResponse = null;
            questionnaireResponse.responsePdfContentUuid = null;
        }
    }

    private async removeFilesFromExamination(examination: Examination) {
        for (const meas of examination.measurements) {
            meas.content = this.measurementContentJsonToClass(meas.type, meas.content);
            if (meas.content) {
                const fileNames = await meas.content.getFileNames();
                for (const fileName of fileNames) {
                    await this.fileStorageService.deleteFile(fileName);
                }
            }
        }
    }

    private async openConnection(migrate: boolean): Promise<Connection> {
        let dbOptions: ConnectionOptions;
        if (this.platform.is('cordova')) {
            dbOptions = {
                type: 'cordova',
                database: 'mona-database.sqlite',
                location: 'default',
                migrationsTransactionMode: 'none',
            };
        } else {
            dbOptions = {
                type: 'sqljs',
                location: 'browser',
                autoSave: true,
                migrationsTransactionMode: 'none',
            };
        }
        const logging: any = [
            // 'query', // logs all queries.
            'error', // logs all failed queries and errors.
            // 'schema', // logs the schema build process.
            'warn', // logs internal orm warnings.
            // 'info', // logs internal orm informative messages.
            // 'log', // logs internal orm log messages.
        ];
        // if (environment.production) {
        //     logging = false
        // }
        // additional options
        Object.assign(dbOptions, {
            logging,
            synchronize: false,
            entities: [Measurement, DeviceClass, Examination, Patient, MeasurementDevice, CurafidaQuestionnaire],
            migrations: [
                InitScheme1589286032424,
                AddPdfContentUuid1591191931576,
                AddIsExportPending1591699841611,
                AddCreatedAtLocal1594910882080,
                AddTenantId1598278812130,
                AddCurafidaQuestionnaire1631018203088,
                AddActiveFlagToQuestionnaire1645171018030,
            ],
        });
        const dbConnection = await createConnection(dbOptions);
        if (migrate) {
            await this.runDatabaseMigrations(dbConnection);
        }
        return dbConnection;
    }
}
